import copy from 'copy-to-clipboard'
import Dropdown from '../components/Dropdown'
import React, { useEffect } from "react"
import { GET, POST, DELETE } from '../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const links = useSelector(state => state.links)
  const navigate = useNavigate()

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/links`).then((links)=>{
      Store.dispatch({ type: 'links/set', links })
    })
  }, [])

  const create = (type)=> {
    POST(`/api/accounts/${account.id}/links`, { type }).then((link)=>{
      Store.dispatch(({ type: 'links/add', link }))
      navigate(`/links/${link.id}/edit`)
    })
  }

  const deleteLink = (link)=> {
    let confirmation = confirm(`Are you sure you want to delete ${link.purchase_name || 'this link'}?`)
    if(confirmation) {
      Store.dispatch({ type: 'links/delete', id: link.id })
      DELETE(`/api/accounts/${account.id}/links/${link.id}`)
    }
  }

  let linkListElement
  if(links && links.length) {
    linkListElement = links.map((link, index)=>{
      return(
        <div key={link.id}>
          <NavLink to={`/links/${link.id}/overview`} className="circular-card-interactive p-2 py-3 p-lg-3">
            <div className="row d-flex align-items-center">
              <div className="col-10 line-height-extra-small px-3">
                <div>
                  <span className="font-weight-medium">{ link.purchase_name }</span>
                </div>
                <div className="opacity-50 pt-1">
                  <small className="font-weight-medium">{ link.type.charAt(0).toUpperCase() + link.type.slice(1) }</small>
                </div>
              </div>
              <div className="col-2 text-end">
                <Dropdown
                  toggle={
                    <button className="btn btn-action-hover" type="button">
                      <i className="fas fa-ellipsis-h text-black-50"></i>
                    </button>
                  }
                  children={[
                    ['Overview', ()=>{ navigate(`/links/${link.id}/overview`) }, <i className="fa-solid fa-chart-line me-2"></i>],
                    ['Copy Link', ()=>{ copy(`https://link.depay.com/${link.external_id}`) }, <i className="far fa-copy me-2"></i>],
                    ['Edit', ()=>{ navigate(`/links/${link.id}/edit`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                    ['Delete', ()=>{ deleteLink(link) }, <i className="far fa-trash-alt me-2"></i>],
                  ]}
                />
              </div>
            </div>
          </NavLink>
        </div>
      )
    })
  } else {
    linkListElement = (
      <div className="d-block w-100 text-center px-5 pb-4">
        <div className="">
          <div className="pb-2 pt-1 text-center text-sm-start">
            <div className="mascot mascot-link d-inline-block d-sm-block"></div>
          </div>
          <div className="font-size-l pt-2 text-center text-sm-start text-lg-center">Create your first link</div>
          <div className="font-size-m pt-1 text-center text-sm-start text-lg-center opacity-50">and receive multiple payments</div>
        </div>
      </div>
    )
  }

  return(
    <div className="links-screen px-3 pt-3">

      <div className="row pt-5 pb-5">
        <div className="col-12 col-xl-6">
          
          <div className="pt-2">
            <div className="d-flex pb-2">
              <Dropdown
                direction='left'
                toggle={
                  <button type="button" className="btn btn-action btn-sm" title="Create a new link">
                    <i className="fa-solid fa-plus me-2"></i>
                    New Link
                  </button>
                }
                children={[
                  ['Payment Link', ()=>{ create('payment') }],
                  ['Donation Link', ()=>{ create('donation') }],
                  ['Tip Link', ()=>{ create('tip') }],
                ]}
              />
            </div>
            <div className="card d-block px-0 py-1">
              { linkListElement }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
