import AccountIcon from "../../components/AccountIcon"
import Dropdown from '../../components/Dropdown'
import moment from 'moment'
import React, { useEffect, useState } from "react"
import switchAccount from '../../helpers/switchAccount'
import UserSettingsSidebar from '../../components/UserSettingsSidebar'
import { GET, POST } from '../../helpers/Queries'
import { NavLink, useParams, useNavigate } from "react-router-dom"
import { Store } from '../../Store'
import { useSelector } from "react-redux"

export default function(props) {

  const user = useSelector(state => state.user)
  const currentAccount = useSelector(state => state.account)
  const accounts = useSelector(state => state.accounts)
  const navigate = useNavigate()

  useEffect(()=>{
    GET('/api/accounts').then((accounts)=>{
      Store.dispatch({ type: 'accounts/set', accounts })
    })
  }, [])

  const createNewAccount = ()=>{
    let confirmation = confirm(`Are you sure you want to create an additional account?`)
    if(confirmation) {
      POST('/api/accounts').then((account)=>{
        Store.dispatch(({ type: 'LOGOUT' }))
        Store.dispatch(({ type: 'user/set', user }))
        Store.dispatch({ type: 'account/select', account })
        navigate('/settings')
      })
    }
  }

  const _switchAccount = (account)=>{
    switchAccount(account).then(()=>navigate('/'))
  }

  return(

    <div className="accounts-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0 order-md-1">
        <UserSettingsSidebar/>
      </div>

      <div className="d-block col-12 col-md-9 pt-2 pt-md-5 px-2 px-md-5 ps-md-3 pr-md-0 d-md-flex justify-content-end">

        <div className="col-lg-8">

          <div className="d-block pt-3">
            <button onClick={createNewAccount} type="button" className="btn btn-action btn-sm" title="Create additional account">
              <i className="fa-solid fa-plus me-2"></i>
              New account
            </button>
          </div>

          <div className="pt-2">
            <div className="card d-block p-0">
              {
                accounts.map((account, index)=>{
                  return(
                    <div key={index} className="pt-1">
                      <div className="p-2 py-3 p-lg-3">
                        <div className="d-flex align-items-center">
                          <div className="ps-2">
                            <AccountIcon account={ account }/>
                          </div>
                          <div className="flex-grow-1 line-height-extra-small px-3">
                            <div>
                              <span className="font-weight-medium">{ account.name || "Account" }</span>
                            </div>
                            <div className="opacity-50">
                              <small>created { moment(account.created_at).format('LL') }</small>
                            </div>
                          </div>
                          <div className="text-end pe-2">
                            { (currentAccount.id != account.id) &&
                              <button onClick={()=>_switchAccount(account)} type="button" className="btn btn-action">
                                Switch
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              
            </div> 
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fas fa-info-circle me-2"></i>
                List of all accounts you have access to.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
