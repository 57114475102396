import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import CountryTypeahead from '../../components/CountryTypeahead'
import React, { useState, useEffect } from "react"
import TaxIdFormat from '../../helpers/TaxIdFormat'
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const [ loaded, setLoaded ] = useState(false)
  const [ billingName, setBillingName ] = useState()
  const [ billingAddressLine1, setBillingAddressLine1 ] = useState()
  const [ billingAddressLine2, setBillingAddressLine2 ] = useState()
  const [ billingCity, setBillingCity ] = useState()
  const [ billingRegion, setBillingRegion ] = useState()
  const [ billingZip, setBillingZip ] = useState()
  const [ billingCountry, setBillingCountry ] = useState()
  const [ billingTaxId, setBillingTaxId ] = useState()
  const [ vatPlaceholder, setVatPlaceholder ] = useState()
  const [debouncedSave] = useDebounce([
    billingName,
    billingAddressLine1,
    billingAddressLine2,
    billingCity,
    billingRegion,
    billingZip,
    billingCountry,
    billingTaxId,
  ], 500);

  useEffect(()=>{
    GET(`/api/accounts/${account.id}`).then((account)=>{
      setBillingName(account.billing_name)
      setBillingAddressLine1(account.billing_address_line_1)
      setBillingAddressLine2(account.billing_address_line_2)
      setBillingCity(account.billing_address_city)
      setBillingRegion(account.billing_address_region)
      setBillingZip(account.billing_address_zip)
      setBillingCountry(account.billing_address_country)
      setBillingTaxId(account.billing_tax_id)
      Store.dispatch(({ type: 'account/change', account }))
      setLoaded(true)
    })
  }, [])

  useEffect(()=>{
    setVatPlaceholder(TaxIdFormat(billingCountry))
  }, [billingCountry])

  useEffect(()=>{ 
    if(
      account.billing_name != billingName ||
      account.billing_address_line_1 != billingAddressLine1 ||
      account.billing_address_line_2 != billingAddressLine2 ||
      account.billing_address_city != billingCity ||
      account.billing_address_region != billingRegion ||
      account.billing_address_zip != billingZip ||
      account.billing_address_country != billingCountry ||
      account.billing_tax_id != billingTaxId
    ) {
      Store.dispatch({
        type: 'account/change',
        account: { ...account,
          billing_name: billingName,
          billing_address_line_1: billingAddressLine1,
          billing_address_line_2: billingAddressLine2,
          billing_address_city: billingCity,
          billing_address_region: billingRegion,
          billing_address_zip: billingZip,
          billing_address_country: billingCountry,
          billing_tax_id: billingTaxId,
        },
      })
      POST(`/api/accounts/${account.id}/billing`, {
        billing_name: billingName,
        billing_address_line_1: billingAddressLine1,
        billing_address_line_2: billingAddressLine2,
        billing_address_city: billingCity,
        billing_address_region: billingRegion,
        billing_address_zip: billingZip,
        billing_address_country: billingCountry,
        billing_tax_id: billingTaxId,
      })
    }
  }, [debouncedSave])

  if(!loaded){ return null }

  return(
    <div className="account-settings-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0 pb-6">

        <div className="col-lg-7">

          <div className="form-group pb-2 pt-3 mb-2">
            <h5>Billing</h5>

            <div className="pt-1 pb-2">
              <label htmlFor="billingName">
                <h6 className="font-size-s">Name</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingName" value={ billingName || '' } onChange={ (event)=>{setBillingName(event.target.value)} } type="text" className="form-control w-100" placeholder="Entity Inc."></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressLine1">
                <h6 className="font-size-s">Addresss line 1: street name & number</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingAddressLine1" value={ billingAddressLine1 || '' } onChange={ (event)=>{setBillingAddressLine1(event.target.value)} } type="text" className="form-control w-100" placeholder="1600 Amphitheatre Parkway"></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressLine2">
                <h6 className="font-size-s">Addresss line 2 (optional)</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingAddressLine2" value={ billingAddressLine2 || '' } onChange={ (event)=>{setBillingAddressLine2(event.target.value)} } type="text" className="form-control w-100" placeholder="Apartment 5"></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressCity">
                <h6 className="font-size-s">City</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingAddressCity" value={ billingCity || '' } onChange={ (event)=>{setBillingCity(event.target.value)} } type="text" className="form-control w-100" placeholder="Mountain View"></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressRegion">
                <h6 className="font-size-s">Region</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingAddressRegion" value={ billingRegion || '' } onChange={ (event)=>{setBillingRegion(event.target.value)} } type="text" className="form-control w-100" placeholder="CA (California)"></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressZip">
                <h6 className="font-size-s">Postal/ZIP Code</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingAddressZip" value={ billingZip || '' } onChange={ (event)=>{setBillingZip(event.target.value)} } type="text" className="form-control w-100" placeholder="94043"></input>
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingAddressCountry">
                <h6 className="font-size-s">Country</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                 <CountryTypeahead
                  className="py-2"
                  required
                  selected={billingCountry}
                  id="billingAddressCountry"
                  placeholder="Select your country"
                  onChange={(value)=>{ setBillingCountry(value)} }
                />
              </div>
            </div>

            <div className="pt-1 pb-2">
              <label htmlFor="billingTaxId">
                <h6 className="font-size-s">TAX/VAT Code</h6>
              </label>
              <div className='text-center col-12 position-relative d-block'>
                <input id="billingTaxId" value={ billingTaxId || '' } onChange={ (event)=>{setBillingTaxId(event.target.value)} } type="text" className="form-control w-100" placeholder={vatPlaceholder}></input>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
