import AccountReducer from './reducers/Account'
import AccountsReducer from './reducers/Accounts'
import AccountUsersReducer from './reducers/AccountUsers'
import AccountWalletsReducer from './reducers/AccountWallets'
import ApiKeysReducer from './reducers/ApiKeys'
import ApiSecretsReducer from './reducers/ApiSecrets'
import AppsReducer from './reducers/Apps'
import IntegrationLogReducer from './reducers/IntegrationLogs'
import IntegrationsReducer from './reducers/Integrations'
import LinksReducer from './reducers/Links'
import PaymentsForIntegrationReducer from './reducers/PaymentsForIntegration'
import PaymentsForLinkReducer from './reducers/PaymentsForLink'
import PaymentsReducer from './reducers/Payments'
import PaywallReducer from './reducers/Paywall'
import RequestsReducer from './reducers/Requests'
import StatisticsReducer from './reducers/Statistics'
import storage from 'redux-persist/lib/storage'
import SubscriptionReducer from './reducers/Subscription'
import TokensReducer from './reducers/Tokens'
import UserReducer from './reducers/User'
import UsersReducer from './reducers/Users'
import UserWalletsReducer from './reducers/UserWallets'
import { applyMiddleware, createStore } from 'redux'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { 
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'

const appReducer = combineReducers({
  account: AccountReducer,
  accounts: AccountsReducer,
  account_users: AccountUsersReducer,
  account_wallets: AccountWalletsReducer,
  api_keys: ApiKeysReducer,
  api_secrets: ApiSecretsReducer,
  apps: AppsReducer,
  integration_logs: IntegrationLogReducer,
  integrations: IntegrationsReducer,
  links: LinksReducer,
  requests: RequestsReducer,
  payments: PaymentsReducer,
  payments_for_integration: PaymentsForIntegrationReducer,
  payments_for_link: PaymentsForLinkReducer,
  paywall: PaywallReducer,
  statistics: StatisticsReducer,
  subscription: SubscriptionReducer,
  tokens: TokensReducer,
  user: UserReducer,
  users: UsersReducer,
  user_wallets: UserWalletsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') { return appReducer(undefined, action) }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(
  { storage, key: 'app', blacklist: [] },
  rootReducer
)

let Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

let Persistor = persistStore(Store)

export { Store, Persistor }
