import InfiniteScroll from 'react-infinite-scroller'
import PaymentGroup from './Group'
import React from "react"

export default function(props) {

  return(

    <InfiniteScroll
      className="pt-1 pb-4"
      loadMore={ props.loadMore }
      threshold={ 500 }
      hasMore={ props.hasMore }
      loader={
        <div key="loader" className="pt-2 pb-4">
          <div className="card bg-white-transparent skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
      }
    >
      {
        props.payments.groups.map((groupKey)=>{
          return(
            <PaymentGroup payments={ props.payments } link={ props.link } key={ groupKey } groupKey={ groupKey }/>
          )
        })
      }
    </InfiniteScroll>
  )
}
