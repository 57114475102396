import AddressEllipsis from "../../helpers/AddressEllipsis"
import Blockchains from "@depay/web3-blockchains"
import CloseIcon from "../../components/CloseIcon"
import copy from 'copy-to-clipboard'
import moment from 'moment'
import Payment from "../../models/Payment"
import React, { useState, useEffect } from "react"
import Token from "../../models/Token"
import TokenAmountRow from "../../components/TokenAmountRow"
import TooltipDrawer from '../../components/TooltipDrawer'
import { Currency } from '@depay/local-currency'
import { NavLink } from "react-router-dom"
import { TokenImage } from '@depay/react-token-image'
import { useSelector } from 'react-redux'

export default function(props) {

  const blockchain = Blockchains[props.payment.data.blockchain]
  const tokens = useSelector(state => state.tokens)
  const user = useSelector(state => state.user)
  const [ showFormData, setShowFormData ] = useState(false)
  const [ showCopiedAllFormData, setShowCopiedAllFormData ] = useState(false)
  const [ displayedCurrencyAmount, setDisplayedCurrencyAmount ] = useState()
  const [ showCopiedIndividualFormData, setShowCopiedIndividualFormData ] = useState([])
  const formatFormDataKey = (key)=>{
    key = key.replace(/_/, ' ')
    key = key.charAt(0).toUpperCase() + key.slice(1)
    return key
  }
  const copyAllFormData = ()=>{
    setShowCopiedAllFormData(true)
    copy(
      Object.keys(props.payment.data.form_data).map((key)=>{
        return `${formatFormDataKey(key)}: ${props.payment.data.form_data[key]}`
      }).join("\n")
    )
  }
  const copyIndividualFormData = (index)=>{
    let newShowCopiedIndividualFormData = [...showCopiedIndividualFormData]
    newShowCopiedIndividualFormData[index] = true
    setShowCopiedIndividualFormData(newShowCopiedIndividualFormData)
    copy(Object.values(props.payment.data.form_data)[index])
  }

  useEffect(()=>{
    Currency.fromUSD({ amount: props.payment.amountUsd, code: user.currency }).then((currencyAmount)=>{
      setDisplayedCurrencyAmount(currencyAmount.toString())
    }).catch(()=>{
      setDisplayedCurrencyAmount(props.payment.usdAmountFormatted)
    })
  }, [])

  return(
    <div className="bg-grey shadow rounded d-block px-3 px-lg-4">
      <div className="d-flex d-lg-none pt-3 pb-3 justify-content-between align-items-center">
        <div className="">
          <span className="dialog-header-title-text">Payment</span>
        </div>
        <div className="">
          <NavLink to={props.linkBack} className="btn-dialog-control bg-dark-grey" title="Close Payment Details">
            <CloseIcon/>
          </NavLink>
        </div>
      </div>
      <div className="row pt-lg-4">
        <div className="col-3 col-lg-4">
          <div className="bg-white d-inline-block rounded-circle" style={{ height: '60px', width: '60px' }}>
            <div className='position-relative d-inline-block'>
              <TokenImage
                className="token-image-full"
                blockchain={props.payment.data.blockchain}
              address={props.payment.tokenId}
              />
              <img className="blockchain-logo-medium" style={{ backgroundColor: blockchain.logoBackgroundColor }} src={ blockchain.logo } title={`on ${ blockchain.label }`}/>
            </div>
          </div>
        </div>
        <div className="col-9 col-lg-8 text-end pt-2">
          <div className="font-size-xl line-height-extra-small">
            <strong className="font-size-l">
              { displayedCurrencyAmount }
            </strong>
          </div>
          { tokens.byId[`${props.payment.data.blockchain}-${props.payment.tokenId}`] &&
            <div className="line-height-extra-small pt-2 opacity-50">
              { props.payment.amountPrefix } { tokens.byId[`${props.payment.data.blockchain}-${props.payment.tokenId}`].symbol } { props.payment.amountToken }
            </div>
          }
          <div className="pt-2">
            { moment(props.payment.data.confirmed_at).format('LLL') }
          </div>
        </div>
      </div>
      <div className="pt-4 pb-3">

        <div className="row bg-white rounded-1 mb-2 p-2 px-3">
          <div className="col-4 opacity-50">
            Blockchain
          </div>
          <div className="col-8 text-end">
            { props.payment.blockchain.label }
          </div>
        </div>

        {
          ['sent', 'both'].includes(props.payment.direction) &&
          <div>
            <div className="row bg-white rounded-1 mb-2 p-2 px-3">
              <div className="col-4 opacity-50">
                Sent
              </div>
              <div className="col-8 text-end">
                <TokenAmountRow
                  blockchain={ props.payment.data.blockchain }
                  tokenId={ props.payment.data.sender_token_id }
                  amount={ props.payment.data.sender_amount }
                  amountUsd={ props.payment.data.sender_amount_usd }
                />
              </div>
            </div>
          </div>
        }

        {
          ['received', 'both'].includes(props.payment.direction) &&
          <div>
            <div className="row bg-white rounded-1 mb-2 p-2 px-3">
              <div className="col-4 opacity-50">
                Received
              </div>
              <div className="col-8 text-end">
                <TokenAmountRow
                  blockchain={ props.payment.data.blockchain }
                  tokenId={ props.payment.data.receiver_token_id }
                  amount={ props.payment.data.receiver_amount }
                  amountUsd={ props.payment.data.receiver_amount_usd }
                />
              </div>
            </div>
          </div>
        }

        <div className="row bg-white rounded-1 mb-2 p-2 px-3">
          <div className="col-4 opacity-50">
            From
          </div>
          <div className="col-8 text-end">
            { AddressEllipsis(props.payment.data.sender_id) }
          </div>
        </div>

        <div className="row bg-white rounded-1 mb-2 p-2 px-3">
          <div className="col-4 opacity-50">
            To
          </div>
          <div className="col-8 text-end">
            { AddressEllipsis(props.payment.data.receiver_id) }
          </div>
        </div>

        <div className="row bg-white rounded-1 mb-2 p-2 px-3">
          <div className="col-4 opacity-50">
            Explorer
          </div>
          <div className="col-8 text-end">
            <a href={props.payment.explorerUrl} target="_blank" rel="noopener noreferrer">
              Link
              <i className="ps-2 fas fa-external-link-alt"></i>
            </a>
          </div>
        </div>

        { props.payment.data.form_data && Object.keys(props.payment.data.form_data).length &&
          <div className={`dropdown-extension ${showFormData ? 'open' : 'closed'}`}>
            <div className="row bg-white rounded-1 mb-2 p-2 px-3">
              <div className="col-4 position-relative">
                <span className="opacity-50 me-1">Form data</span>
                <button className="btn btn-inline-link" type="button" onClick={copyAllFormData} title="Copy all form data to clipboard">
                  <i className="far fa-copy"/>
                </button>
                <div className="position-absolute w-100">
                  <TooltipDrawer show={showCopiedAllFormData} set={setShowCopiedAllFormData} text={ "Copied to clipboard" }/>
                </div>
              </div>
              <div className="col-8 text-end">
                <button type="button" className="btn btn-inline-link" rel="noopener noreferrer"
                  onClick={(event)=>{
                    setShowFormData(!showFormData)
                    event.preventDefault()
                    return false
                  }}
                >
                  { showFormData && <span>Hide</span>}
                  { !showFormData && <span>Expand</span>}
                  <i className="fas fa-chevron-down ms-2 pr-1"></i>
                </button>
              </div>
            </div>
            { Object.keys(props.payment.data.form_data).map((key, index)=>{
              return (
                <div className="dropdown-extension-target mb-2 position-relative" key={key}>
                  <div className="row d-flex bg-white rounded-1 p-2 px-3">
                    <div className="col-6 opacity-50 text-truncate" title={ formatFormDataKey(key) }>
                      { formatFormDataKey(key) }
                    </div>
                    <div className="col-6 text-end position-relative" title={ props.payment.data.form_data[key] } onClick={()=>copyIndividualFormData(index)}>
                      <span className="text-truncate d-block w-100">{ props.payment.data.form_data[key] }</span>
                      <TooltipDrawer show={showCopiedIndividualFormData[index]} set={()=>{
                        let newShowCopiedIndividualFormData = [...showCopiedIndividualFormData]
                        newShowCopiedIndividualFormData[index] = false
                        setShowCopiedIndividualFormData(newShowCopiedIndividualFormData)
                      }} text={ "Copied to clipboard" }/>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}
