import AcceptedPayments from '../../components/integrations/edit/AcceptedPayments'
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import FileUploader from '../../components/FileUploader'
import React, { useState, useEffect } from "react"
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const [ accepted, setAccepted ] = useState(account.accepted)

  useEffect(()=>{
    if(account.accepted != accepted) {
      Store.dispatch({
        type: 'account/change',
        account: { ...account, accepted },
      })
      POST(`/api/accounts/${account.id}`, { accepted })
    }
  }, [accepted])

  return(
    <div className="account-tokens-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0 pb-6">

        <div className="col-lg-7">

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="accountName" className="ps-1">
              <h5>Tokens</h5>
            </label>

            <div className="px-2 pt-1">
                <small className="text-muted">
                  Please select the tokens that you would like to receive as means of payment for the entire account.
                </small>
            </div>


            <div className="pt-3 pb-2">
                <AcceptedPayments
                  hideAccountSettings={ true }
                  acceptedPayments={ accepted || [] }
                  onChange={ (accepted)=>{ setAccepted(accepted) }}
                />
              </div>
          </div>

          <div className="px-2 pt-1">
            <small className="text-muted">
              <i className="fas fa-info-circle me-2"></i>
              You will still be able to apply instance-specific configurations to links, requests, integrations, etc. overriding account-wide settings.
            </small>
          </div>

        </div>
      </div>
    </div>
  )
}
