import AcceptedPayments from '../../components/integrations/edit/AcceptedPayments'
import Blockchains from '@depay/web3-blockchains'
import copy from 'copy-to-clipboard'
import isValidAddress from '../../helpers/isValidAddress'
import LightOrDark from '../../helpers/LightOrDark'
import moment from 'moment'
import React, { useState, useEffect, useRef } from "react"
import TooltipDrawer from '../../components/TooltipDrawer'
import Web3Exchanges from '@depay/web3-exchanges'
import { all } from '../../helpers/Currencies'
import { Currency } from '@depay/local-currency'
import { ethers } from 'ethers'
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

const VALID_EMAIL_REGEXP = /^\w+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function(props) {

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const [ displayedPrice, setDisplayedPrice ] = useState()
  const [ request, setRequest ] = useState()
  const [ showTooltip, setShowTooltip ] = useState()
  const [ invalidEmail, setInvalidEmail ] = useState(false)
  const [ blockchains, setBlockchains ] = useState()
  const backgroundColor = account.color || '#ea357a'
  const textColor = account.color ? (LightOrDark(backgroundColor) == 'light' ? '#000000' : '#FFFFFF') : '#FFFFFF'
  const [ invalidPriceConfiguration, setInvalidPriceConfiguration ] = useState()
  const [ invalidTokensConfiguration, setInvalidTokensConfiguration ] = useState()
  const [ invalidAmountConfiguration, setInvalidAmountConfiguration ] = useState()
  const [ invalidReceiverConfiguration, setInvalidReceiverConfiguration ] = useState()
  const [ invalidConfiguration, setInvalidConfiguration ] = useState()
  const [ debouncedRequest ] = useDebounce(request, 500)
  const tokensHeadlineElement = useRef()
  const priceInputElement = useRef()

  const updateRequest = (data) => {
    if(data.displayed_currency && data.displayed_currency === 'local'){ data.displayed_currency = undefined }
    setRequest(Object.assign({}, request, data))
  }

  const deliverRequest = (request) => {
    POST(`/api/accounts/${account.id}/requests/${request.id}/deliver`)
    const newRequest = {...request, last_delivery_at: new Date().toJSON() }
    setRequest(newRequest)
  }

  const updateDisplayedPrice = async(request)=> {
    if(!request){ return }
    let acceptedPayments = request.accepted
    if(request?.currency) {
      if(request?.price?.length) {
        let displayCurrency = request.displayed_currency === 'local' ? undefined : request.displayed_currency
        let rate = await Currency.rate({ from: request.currency, to: displayCurrency })
        setDisplayedPrice((new Currency({ amount: (request.price/rate), code: displayCurrency })).toString())
      } else {
        setDisplayedPrice()
      }
    } else if(acceptedPayments && acceptedPayments.length){
      if(acceptedPayments.length == 1 && acceptedPayments[0].amount) {
        setDisplayedPrice(`${acceptedPayments[0].token.symbol} ${acceptedPayments[0].amount}`)
      } else if(acceptedPayments.every((payment)=>{ return !!payment.amount })) {
        let allPaymentsWithAmount = acceptedPayments.filter((payment)=>{ return !!payment.amount })
        Promise.all(allPaymentsWithAmount.map((payment)=>{
          let USD = Blockchains[payment.token.blockchain].stables.usd.find((stable)=>stable===payment.token.address)
          if(USD) { USD = Blockchains[payment.token.blockchain].tokens.find((token)=>token.address===USD) }
          if(USD){
            return Promise.resolve([{
              amountOut: ethers.utils.parseUnits(payment.amount, USD.decimals),
              exchange: {
                blockchain: payment.token.blockchain
              },
              decimals: USD.decimals,
            }])
          } else {
            return Web3Exchanges.route({
              blockchain: payment.token.blockchain,
              tokenIn: payment.token.address,
              tokenOut: Blockchains[payment.token.blockchain].stables.usd[0],
              amountIn: parseFloat(payment.amount)
            })
          }
        })).then(async (routes)=>{
          let usdAmounts = routes.map((routes)=>{
            return ethers.utils.formatUnits(
              routes[0].amountOut,
              routes[0].decimals || Blockchains[routes[0].blockchain].tokens.find((token)=>token.address===Blockchains[routes[0].blockchain].stables.usd[0]).decimals,
            )
          })
          let usdMaxAmount = Math.max(...usdAmounts)
          let displayCurrency = request.displayed_currency === 'local' ? undefined : request.displayed_currency
          let rate = await Currency.rate({ from: 'USD', to: displayCurrency })
          setDisplayedPrice((new Currency({ amount: (usdMaxAmount/rate), code: displayCurrency })).toString())
        })
      } else {
        setDisplayedPrice()
      }
    } else if(account.accepted?.length > 0 && request.amounts) {
      if(account.accepted.length == 1 && request.amounts[0]?.amount?.length) {
        setDisplayedPrice(`${account.accepted[0].token.symbol} ${request.amounts[0].amount}`)
      } else if (request?.amounts && request.amounts.length && !request.price) {
        Promise.all(account.accepted.map((payment)=>{
          let USD = Blockchains[payment.token.blockchain].stables.usd.find((stable)=>stable===payment.token.address)
          if(USD) { USD = Blockchains[payment.token.blockchain].tokens.find((token)=>token.address===USD) }
          let amount = request.amounts.find((amount)=>{ return amount.token.blockchain === payment.token.blockchain && amount.token.address === payment.token.address  })?.amount
          if(!amount || amount === 0) { return Promise.resolve([]) }
          if(USD){
            return Promise.resolve([{
              amountOut: ethers.utils.parseUnits(amount, USD.decimals),
              exchange: {
                blockchain: payment.token.blockchain
              },
              decimals: USD.decimals,
            }])
          } else {
            return Web3Exchanges.route({
              blockchain: payment.token.blockchain,
              tokenIn: payment.token.address,
              tokenOut: Blockchains[payment.token.blockchain].stables.usd[0],
              amountIn: parseFloat(amount)
            })
          }
        })).then(async (routes)=>{
          if(routes && routes.length) {
            let usdAmounts = routes.map((routes)=>{
              if(!routes[0]) { return '0' }
              return ethers.utils.formatUnits(
                routes[0].amountOut,
                routes[0].decimals || Blockchains[routes[0].blockchain].tokens.find((token)=>token.address===Blockchains[routes[0].blockchain].stables.usd[0]).decimals,
              )
            })
            let usdMaxAmount = Math.max(...usdAmounts)
            let displayCurrency = request.displayed_currency === 'local' ? undefined : request.displayed_currency
            let rate = await Currency.rate({ from: 'USD', to: displayCurrency })
            setDisplayedPrice((new Currency({ amount: (usdMaxAmount/rate), code: displayCurrency })).toString())
          }
        })
      }

    } else {
      setDisplayedPrice()
    }
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/requests/${id}`).then((request)=>{
      if(!request.delivery_type){ request.delivery_type = 'email' }
      setRequest(request)
      setInvalidEmail(request.payer_email?.length > 0 ? !request.payer_email.match(VALID_EMAIL_REGEXP) : false )
    })
  }, [])

  useEffect(()=>{
    updateDisplayedPrice(debouncedRequest)
  }, [debouncedRequest])

  useEffect(()=>{
    if(request?.accepted) {
      setBlockchains([...new Set(request.accepted.map((configuration)=>configuration?.token?.blockchain).filter(Boolean))])
    } else {
      setBlockchains([...new Set((account?.accepted || []).map((configuration)=>configuration?.token?.blockchain).filter(Boolean))])
    }
  }, [account, request])

  useEffect(()=>{
    if(!request){ return }

    let invalidTokensConfiguration = false
    let invalidPriceConfiguration = false
    let invalidAmountConfiguration = false
    let invalidReceiverConfiguration = false

    if(request?.accepted) {
      invalidTokensConfiguration = !request.accepted.length
    } else {
      invalidTokensConfiguration = !account?.accepted?.length
    }

    if(!invalidTokensConfiguration) {

      if(request.currency) {
        invalidPriceConfiguration = (!request.price || request.price <= 0)
      } else { // denominated in token
        if(request?.accepted) {
          invalidAmountConfiguration = !request?.accepted || request?.accepted.length <= 0 || !!request.accepted.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))
        } else {
          invalidAmountConfiguration = request.amounts && (!account.accepted || account.accepted.length <= 0 || !!request.amounts.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))) || !request.amounts
        }
      }

      const acceptedConfiguration = request?.accepted || account?.accepted
      invalidReceiverConfiguration = !!(acceptedConfiguration || []).find((configuration)=>{
        return !configuration.receiver || !isValidAddress(configuration?.token?.blockchain, configuration.receiver)
      })
    }

    setInvalidTokensConfiguration(invalidTokensConfiguration)
    setInvalidPriceConfiguration(invalidPriceConfiguration)
    setInvalidAmountConfiguration(invalidAmountConfiguration)
    setInvalidReceiverConfiguration(invalidReceiverConfiguration)

    setInvalidConfiguration(
      invalidTokensConfiguration ||
      invalidPriceConfiguration ||
      invalidAmountConfiguration ||
      invalidReceiverConfiguration
    )
  }, [request])

  useEffect(()=>{
    if(debouncedRequest != undefined) {
      POST(`/api/accounts/${account.id}/requests/${request.id}`, debouncedRequest)
      Store.dispatch({ type: 'requests/update', request: debouncedRequest })
    }
  }, [ debouncedRequest ])

  if(request && request.completed_at) {
  
    return(null)
  
  } else if (request) {

    return(

      <div className="new-request-screen px-3 pt-3">

        <div className="row">

          <div className="col-lg-5 pt-5 pb-5">

            <div className="form-group pb-2 pt-1">
              <div>
                <label className="pb-1 user-select-none">
                  <h5>Amount</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !!request.currency } onChange={ ()=>{ 
                      let newRequest = JSON.parse(JSON.stringify(request))
                      updateRequest({...newRequest, currency: request.currency || 'USD', price: '' })
                    }}/>
                    Denominated in a currency
                  </label>
                </div>
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !request.currency } onChange={ ()=>{ 
                      updateRequest({ currency: undefined, price: undefined })
                    }}/>
                    Denominated per token
                  </label>
                </div>
              </div>
              {
                request.currency &&
                <div className="pt-1 pb-1 row">
                  <div className="col-4">
                    <select className="form-select" value={ request.currency } onChange={ (event)=>{ 
                      updateRequest({ currency: event.target.value }) 
                    }}>
                      { all.map((currency)=>{
                          return(
                            <option key={ currency } value={ currency }>{ currency }</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="col-8 ps-2">
                    <input
                      required
                      className="form-control w-100"
                      placeholder="Enter amount"
                      ref={priceInputElement}
                      onChange={ (event)=>{ 
                        updateRequest({ price: event.target.value }) 
                      }}
                      onBlur={ (event)=>{ 
                        let value = event.target.value
                        if(value && value.length) {
                          updateRequest({ price: parseFloat(value).toFixed(2) }) 
                        }
                      }}
                      type="number"
                      step="1"
                      value={ request.price || '' }
                    />
                  </div>
                </div>
              }

              <div className="pb-1 pt-3">
                <label className="form-check-label user-select-none w-100">
                  Displayed currency
                  <select className="form-select mt-2 w-100" value={ request.displayed_currency || 'local' } name="displayedPrice" onChange={ (event)=>{ 
                    updateRequest({ displayed_currency: event.target.value }) 
                  }}>
                    <option key="local" value="local">User's local currency (recommended)</option>
                    { all.map((currency)=>{
                        return(
                          <option key={ currency } value={ currency }>{ currency }</option>
                        )
                      })
                    }
                  </select>
                </label>
              </div>
            </div>

            <div className="form-group">
              <div className="pt-3">
                <label className="pb-1 user-select-none position-relative">
                  <a name="tokens" className="anchor"/>
                  <h5 ref={tokensHeadlineElement}>Tokens</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  Please select the tokens that you would like to receive as means of payment:
                </small>
              </div>

              <div className="pt-3 pb-1">
                <AcceptedPayments
                  acceptedPayments={ request.accepted }
                  amounts={ request.amounts }
                  withAmounts={ !request.currency }
                  onChange={ (accepted)=>{
                    if(request.accepted || accepted?.length === 0 || !account?.accepted || account.accepted.length == 0) {
                      let newRequest = JSON.parse(JSON.stringify(request))
                      newRequest.accepted = accepted
                      newRequest.amounts = undefined
                      setRequest(newRequest)
                    } else {
                      let newRequest = JSON.parse(JSON.stringify(request))
                      newRequest.accepted = undefined
                      newRequest.amounts = accepted.map((configuration)=>{
                        return({
                          amount: configuration.amount,
                          token: configuration.token
                        })
                      })
                      setRequest(newRequest)
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-group pb-2">
              <div className="pt-3">
                <label htmlFor="due_date" className="pb-1 user-select-none">
                  <h5>Due Date</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  Please select the date at which this payment is due the latest:
                </small>
              </div>
              <div className="pt-2">
                <input
                  id="due_date"
                  type="date"
                  className="form-control"
                  value={ request.due_date || '' }
                  min={moment().format('YYYY-MM-DD')}
                  onChange={(event)=>updateRequest({ due_date: event.target.value }) }
                />
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label htmlFor="what" className="pb-1 user-select-none">
                  <h5 className="m-0 p-0 line-height-small">What</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  Enter what the payment is for (optional):
                </small>
              </div>
              <div className="pt-2">
                <textarea
                  required
                  id="what"
                  style={{ maxHeight: '170px' }}
                  className="form-control w-100"
                  placeholder="Service XYZ"
                  type="text"
                  onChange={(event)=>updateRequest({ what: event.target.value }) }
                  value={ request.what || '' }
                />
              </div>
            </div>

            <div className="form-group pb-2">
              <div className="pt-3">
                <label htmlFor="payers_name" className="pb-1 user-select-none">
                  <h5>Payer</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  Enter the name of the payer (optional):
                </small>
              </div>
              <div className="pt-2">
                <input
                  required
                  id="payers_name"
                  className="form-control w-100"
                  placeholder="Satoshi Nakamoto"
                  type="text"
                  onChange={(event)=>updateRequest({ payer_name: event.target.value }) }
                  value={ request.payer_name || '' }
                />
              </div>
            </div>

            <div className="form-group pb-lg-6">
              <div className="pt-3">
                <label htmlFor="delivery" className="pb-1 user-select-none">
                  <h5>Delivery</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  How do you want to deliver the request and reminders?
                </small>
              </div>
              <div>
                <select id="delivery" className="form-select mt-2 w-100" value={ request.delivery_type || 'email' } onChange={ (event)=>{ 
                  updateRequest({ delivery_type: event.target.value }) 
                }}>
                  <option key="email" value="email">Delivery by e-mail</option>
                  <option key="link" value="link">I will deliver it myself</option>
                </select>
              </div>
              { request.delivery_type === 'email' &&
                <div>
                  <div className="pt-1 input-group has-validation">
                    <input
                      required
                      className={`form-control w-100 ${ invalidEmail ? 'is-invalid border border-danger' : '' }`}
                      placeholder="payer@example.com"
                      type="email"
                      onChange={(event)=>{
                        const email = event.target.value
                        updateRequest({ payer_email: email })
                        setInvalidEmail(!email.match(VALID_EMAIL_REGEXP))
                      }}
                      value={ request.payer_email || '' }
                    />
                  </div>
                  {
                    invalidEmail &&
                    <div>
                      <input type="hidden" className="form-control is-invalid"/>
                      <div className="invalid-feedback font-size-m ps-2 font-weight-bold">
                        Please enter a valid email!
                      </div>
                    </div>
                  }
                  {
                    !!request.last_delivery_at &&
                    <div className="pt-2 ps-1 text-black-50">
                      <small>
                        <i className="fa-regular fa-envelope me-2"></i>
                        Last delivery: { moment(request.last_delivery_at).fromNow() }
                      </small>
                    </div>
                  }
                  { request.payer_email && !invalidEmail && (!request.last_delivery_at || moment().diff(moment(request.last_delivery_at), 'days') > 0) &&
                    <div>
                      {
                        !request.last_delivery_at &&
                        <div className="pt-2">
                          <button type="button" className="btn btn-action btn-sm me-3" onClick={ ()=>deliverRequest(request) } title="Click to deliver the request">
                            <i className="fa-solid fa-envelope me-2"></i>
                            Deliver now
                          </button>
                        </div>
                      }
                      {
                        request.last_delivery_at &&
                        <div className="pt-2">
                          <button type="button" className="btn btn-action btn-sm me-3" onClick={ ()=>deliverRequest(request) } title="Click to deliver a reminder">
                            <i className="fa-solid fa-envelope me-2"></i>
                            Send reminder
                          </button>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>

          </div>

          <div className="col-lg-7 sticky-wrapper">
            <div className="sticky-item">
              <div className="pt-1 pe-2">
                <div className="ps-0 ps-lg-5 pt-0 pt-lg-5 pb-6">

                  { invalidConfiguration === true &&
                    <div className="border-danger alert alert-info mb-2 rounded-1 px-2 py-1 d-inline-block w-100">
                      <div className="px-1 pt-1">
                        <h5 className="text-danger ms-1 flex-grow-1">Invalid configuration</h5>
                      </div>
                      <div>
                        <div className="mb-2 pt-1 w-100">
                          { invalidTokensConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please add tokens you accept as payment</button> }
                          { invalidPriceConfiguration && <button onClick={()=>priceInputElement.current.focus()} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid price</button> }
                          { invalidAmountConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set valid amounts for all tokens</button> }
                          { invalidReceiverConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid receiver for each token</button> }
                        </div>
                      </div>
                    </div>
                  }

                  { invalidConfiguration === false &&
                    <div className="pt-2">
                      <h2 className="h5 pt-1 text-black opacity-50 font-size-s">
                        Request Link
                      </h2>
                      <div>
                        <div>
                          <div className="row w-100">
                            <div className="position-relative">
                              <div className="d-flex position-relative">
                                <div role="button" onClick={()=>{ 
                                  var range = document.createRange();
                                  range.selectNode(document.getElementById('link'))
                                  window.getSelection().removeAllRanges()
                                  window.getSelection().addRange(range)
                                  setShowTooltip(true)
                                  copy(`https://request.depay.com/${request.id}`)
                                }} id="link" className="border rounded-1 px-2 py-1 btn-action white">
                                  <div className="fs-4 px-1 w-100 text-truncate link-button-text">
                                    { `request.depay.com/${request.id}` }
                                  </div>
                                </div>
                                <button className='border col-2 btn btn-sm px-1 rounded-1 ms-1 btn-action white d-none d-md-inline-block' style={{ position: 'relative', height: '3.1rem', top: 0 }} 
                                  onClick={()=>{
                                    setShowTooltip(true)
                                    copy(`https://request.depay.com/${request.id}`)
                                  }} 
                                  title="Click to copy"
                                >
                                  <i className="far fa-copy fs-5"></i>
                                </button>
                              </div>
                              <TooltipDrawer show={showTooltip} set={setShowTooltip} text={ "Copied to clipboard" }/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-2 pb-3">

                        <div className="row pt-3 pb-1">
                          <div className="col-6">
                            <h2 className="h5 pt-2 text-black opacity-50 font-size-s">
                              Preview
                            </h2>
                          </div>
                        </div>

                        <div className="rounded-2 border shadow w-100 mb-3">

                          <div className="w-100">

                            <div className="d-flex justify-content-end h-100 px-4 pb-4 pt-2" style={{ borderTopLeftRadius: '0.8rem', borderTopRightRadius: '0.8rem', color: textColor, backgroundColor: backgroundColor }}>

                              <div className="container row pe-0 justify-content-end pt-4">

                                <div className="position-relative pb-3 d-flex align-items-center">
                                  { account.logo &&
                                    <div className="rounded-circle d-inline-block me-2" style={{ position: 'relative', top: '-2px', width: "30px", height: "30px", backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(https://uploads.depay.com/${account.logo})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                                  }
                                  <span>{ account && account.name ? `${account.name}` : null }</span>
                                </div>

                                <div className="pt-3 align-items-center pb-1">

                                  <div className="pe-3 pb-1">
                                    <h5 className="fs-2 mb-0 fw-bold" style={{ color: textColor }}>{ displayedPrice }</h5>
                                  </div>

                                  { request.due_date && 
                                    <div>
                                      <span className="opacity-50" style={{ color: textColor }}>
                                        Due { moment(request.due_date).format("MMMM DD, YYYY") }
                                      </span>
                                    </div>
                                  }

                                </div>

                                { request?.what?.length > 0 &&
                                  <div className="flex-grow-1">

                                    <div>
                                      <div className="pt-3">

                                        <div className="d-flex align-items-center pt-1">
                                          <div className="flex-grow-1 lh-sm" style={{whiteSpace: 'pre-line'}}>
                                            { request.what }
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>

                          <div className="container row bg-grey px-4 pt-4 rounded-bottom pb-4">

                            <h2 className="h5 mb-0 letter-spacing-s">
                              Pay with crypto
                            </h2>

                            <div className="pt-1 pb-3">
                              { blockchains?.map((name, index)=>{
                                let blockchain = Blockchains.findByName(name)
                                return(
                                  <img key={name} className={`rounded-1 ${index > 0 ? 'ms-1' : ''}`} title={`Supports payments on ${blockchain.label}`} src={ blockchain.logo } style={{ backgroundColor: blockchain.logoBackgroundColor, boxShadow: '0 0 4px rgba(0,0,0,0.08)', width: "28px", height: "28px", padding: '1px' }}/>
                                )
                              }) }
                            </div>

                            <div className="pt-2">
                              <button className="btn rounded-2 btn-lg py-2 w-100" style={{ background: backgroundColor || '#ea357a', borderColor: backgroundColor || '#ea357a', color: textColor || '#FFFFFF' }}>
                                <div className="py-1 w-100">
                                  Pay
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
