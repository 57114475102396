import { Store } from '../Store'

const GET = (url)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'GET'
    }).then((response)=>{
      if(response.status == 200) {
        response.text().then((text)=>{
          try {
            let json = JSON.parse(text)
            resolve(json)
          } catch {
            resolve(text)
          }
        }).catch(reject)
      } else if (response.status == 404) {
        resolve()
      } else if (response.status == 401) {
        Store.dispatch(({ type: 'LOGOUT' }))
        setTimeout(()=>{ window.location = '/' }, 100)
        setTimeout(()=>{ window.location.reload(true) }, 400)
      } else {
        response.text().then((text)=>{
          reject(text || 'Request failed')
        }).catch(reject)
      }
    }).catch(reject)
  }) 
}

const POST = (url, body)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json", "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content 
      }
    }).then((response)=>{
      if(response.status == 200) {
        response.text().then((text)=>{
          try {
            let json = JSON.parse(text)
            resolve(json)
          } catch {
            resolve(text)
          }
        }).catch(reject)
      } else if (response.status == 201) {
        resolve()
      } else if (response.status == 401) {
        Store.dispatch(({ type: 'LOGOUT' }))
        setTimeout(()=>{ window.location = '/' }, 100)
        setTimeout(()=>{ window.location.reload(true) }, 400)
      } else {
        if(response.status == 402) {
          Store.dispatch(({ type: 'paywall/show' }))
          reject('Subscription Required')
        } else {
          response.text().then((text)=>{
            reject(text || 'Request failed')
          }).catch(reject)
        }
      }
    }).catch(reject)
  }) 
}

const DELETE = (url)=>{
  return new Promise((resolve, reject)=>{
    fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json", "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content 
      }
    }).then((response)=>{
      if(response.status == 200) {
        response.text().then((text)=>{
          try {
            let json = JSON.parse(text)
            resolve(json)
          } catch {
            resolve(text)
          }
        }).catch(reject)
      } else if (response.status == 201) {
        resolve()
      } else if (response.status == 401) {
        Store.dispatch(({ type: 'LOGOUT' }))
        setTimeout(()=>{ window.location = '/' }, 100)
        setTimeout(()=>{ window.location.reload(true) }, 400)
      } else {
        if(response.status == 402) {
          Store.dispatch(({ type: 'paywall/show' }))
          reject('Subscription Required')
        } else {
          response.text().then((text)=>{
            reject(text || 'Request failed')
          }).catch(reject)
        }
      }
    }).catch(reject)
  }) 
}

export { GET, POST, DELETE }
