import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const restauration = {}
let locationBefore

export default (props) => {
  
  const location = useLocation();
  
  useEffect(() => {

    if(locationBefore) {
      restauration[locationBefore.key] = { top: window.scrollY, left: window.scrollX };
    }

    if(location.pathname === '/') {
      if(locationBefore && restauration[locationBefore?.key]) {
        document.documentElement.style = "scroll-behavior: auto";
        window.scrollTo({
          top: restauration[locationBefore.key].top,
          left: restauration[locationBefore.key].left,
          behavior: 'instant' 
        });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }

    locationBefore = location;

  }, [location]);

  return <>{props.children}</>
};
