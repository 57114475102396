import AddressEllipsis from "../../helpers/AddressEllipsis"
import CloseIcon from "../../components/CloseIcon"
import DePayWidgets from "@depay/widgets"
import Dropdown from '../../components/Dropdown'
import moment from 'moment'
import React, { useEffect, useState, useMemo, useRef } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { GET, POST } from '../../helpers/Queries'
import { NavLink, useParams, useNavigate } from "react-router-dom"
import { Store } from '../../Store'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useSelector } from "react-redux"

export default function(props) {

  const allWalletsSorted = useMemo(()=>{
    let allWalletsSorted = DePayWidgets.allWallets.map((wallet)=>({ label: wallet.name, value: wallet.name })).sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else {
        return 0;
      }
    })
    allWalletsSorted.unshift({ label: 'Other', value: 'Other' })
    return allWalletsSorted
  }, [])

  const account = useSelector(state => state.account)
  const walletTypeElement = useRef()
  const accountWallets = useSelector(state => state.account_wallets)
  let [ accountWallet, setAccountWallet ] = useState()
  let [ walletName, setWalletName ] = useState(accountWallet?.name)
  let [ selectedWalletType, setSelectedWalletType ] = useState()
  const { id: selectedWalletId } = useParams()
  const navigate = useNavigate()

  const saveSelectedWallet = ()=>{
    const name = (walletName === undefined || walletName.length === 0) ? `Wallet ${AddressEllipsis(accountWallet.external_id)}` : walletName
    const wallet_type = selectedWalletType.value
    Store.dispatch({ type: 'accountWallets/update', accountWallet: { id: accountWallet.id, name, wallet_type }})
    POST(`/api/accounts/${account.id}/account_wallets/${accountWallet.id}`, { name, wallet_type }).then(()=>{
      navigate('/settings/wallets')
    })
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
    })
  }, [])

  useEffect(()=>{
    if(selectedWalletId && accountWallets && accountWallets.length) {
      const accountWallet = accountWallets.find((accountWallet)=>accountWallet.id == selectedWalletId)
      setAccountWallet(accountWallet)
      setWalletName(accountWallet.name)
      setSelectedWalletType(allWalletsSorted.find((wallet)=>wallet.value === accountWallet.wallet_type))
    } else {
      setAccountWallet()
    }
  }, [selectedWalletId, accountWallets])

  if(!accountWallet) { return null }

  return(

    <div className="wallets-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9 col-xl-6 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0">

        <h5 className="pt-3 ps-1 pb-1 mb-1">Edit account wallet</h5>

        <div className="bg-grey shadow rounded d-block px-3 pb-3">
          <div className="row pt-3 pb-1 mb-1">
            <label>
              <span className='d-block pb-1 opacity-50'>
                Wallet Type
              </span>
              <Typeahead
                autocomplete={'off'}
                selected={ [selectedWalletType].filter(Boolean) }
                ref={ walletTypeElement }
                required={ true }
                id={ 'walletType' }
                placeholder={ "Select which wallet" }
                multiple={ false }
                highlightOnlyResult={ true }
                inputProps={{ className: " d-block mb-1 py-2 px-2 rounded-1 bg-white border form-control" }}
                onChange={(selection) => {
                  setSelectedWalletType(selection[0])
                }}
                options={ allWalletsSorted }
              />
            </label>
          </div>
          <div className="row pt-1 pb-1">
            <label>
              <span className='d-block pb-1 opacity-50'>
                Wallet Name
              </span>
              <input placeholder="Payment wallet" type="text" className="row bg-white rounded-1 mb-2 p-2 px-2 form-control" value={ walletName || '' }
                onChange={(event)=>{ setWalletName(event.target.value) }}
              />
            </label>
          </div>
          <div className="row">
            <label>
              <span className='d-block pb-1 opacity-50'>
                Wallet Address
              </span>
              <small type="text" className="d-block bg-white rounded-1 mb-2 p-2 px-2 text-break" title={ accountWallet.external_id }>
                { accountWallet.external_id }
              </small>
            </label>
          </div>
          <div className="row pt-2">
            <button className="btn btn-primary btn-sm w-100" onClick={ saveSelectedWallet }>
              Save
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}
