import ADDRESS_FORMATS from '../helpers/AddressFormats'
import CountryTypeahead from './CountryTypeahead'
import React, { useState, useEffect, useRef } from "react"
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead"
import { GET } from '../helpers/Queries'

export default function(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [country, setCountry] = useState()
  const [address, setAddress] = useState({})
  const [fullName, setFullName] = useState()
  const [fullNameChanged, setFullNameChanged] = useState(false)
  const [showCompleteAddressForm, setShowCompleteAddressForm] = useState(false)
  const [useShippingAddress, setUseShippingAddress] = useState(props.useShippingAddress)
  let typeahead = useRef()

  useEffect(()=>{
    setUseShippingAddress(props.useShippingAddress)
  }, [props.useShippingAddress])

  useEffect(()=>{
    GET(`/api/request/country`).then((data)=>{
      setCountry(data?.country)
    })
  }, [])

  useEffect(()=>{
    if(!fullNameChanged) {
      setFullName(props.fullName)
    }
  }, [props.fullName])

  useEffect(()=>{
    if(props.onChange) {
      props.onChange({
        country
      })
    }
  }, [country])


  const addressFormatIdToLabel = (id, country)=>{
    if(ADDRESS_FORMATS[country]?.labels && ADDRESS_FORMATS[country].labels[id]) {
      return ADDRESS_FORMATS[country].labels[id]
    } else {
      return {
        'address_line_1': 'Address line 1',
        'address_line_2': 'Address line 2',
        'postal_code': 'Postal code',
        'city': 'City',
        'emirate': 'Emirate',
        'province': 'Province',
        'state': 'State',
        'parish': 'Parish',
        'cedex': 'CEDEX',
        'suburb': 'Suburb',
        'neighborhood': 'Neighborhood',
        'island': 'Island',
        'oblast': 'Oblast',
        'district': 'District',
        'department': 'Department',
        'county': 'County',
        'townland': 'Townland',
        'area': 'Area',
        'prefecture': 'Prefecture',
      }[id] || id
    }
  }

  const handleSearch = (query) => {
    setIsLoading(true)

    fetch(`/api/addresses/completion`, {
        method: 'POST',
        body: JSON.stringify({ query, country, language: navigator?.language?.split('-')[0] || 'en' }),
        headers: { "Content-Type": "application/json", "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content }
      }).then((resp) => resp.json())
      .then((items) => {
        setOptions(items.map((item, index)=>{
          return {...item, id: index, label: [[item?.street, item?.housenumber].filter(Boolean).join(' '), (ADDRESS_FORMATS[country].format.flat().map((entry)=>item[entry])).filter(Boolean).join(', ')].filter(Boolean).join(', ')}
        }))
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if(typeahead.current){
      typeahead.current.getInput().setAttribute('required', 'true')
    }
  }, [])

  return(
    <>
      { props.shippingAddress &&
        <div className="mb-2">
          <label>
            <input className="form-check-input" type="checkbox" checked={ useShippingAddress } onChange={ ()=>{
              setUseShippingAddress(!useShippingAddress)
            }}/>
            <span className="font-size-s ps-1">Use shipping address also for billing</span>
          </label>
        </div>
      }
      <div className='form-address-block mb-1'>
        { !useShippingAddress &&
          <>
            <div className='form-address-line'>
              <input required value={ fullName || '' } onChange={(event)=>{
                setFullNameChanged(event.target.value.length > 0 ? true : false)
                setFullName(event.target.value)
              }} type="text" className="d-block form-control mb-1 rounded-1" placeholder={ 'Full name' } name={ 'full_name' } id={ 'full_name' }/>
            </div>
            <div className='form-address-line'>
              <CountryTypeahead
                required
                id={ `${props.id}Country` }
                placeholder="Select country"
                selected={country}
                onChange={(value)=>{ 
                  setCountry(value)
                  setTimeout(()=>{
                    if(typeahead?.current) { typeahead.current.focus() }
                  }, 80)
                }}
              />
            </div>
            { country && !showCompleteAddressForm &&
              <>
                <div className='form-address-line'>
                  <AsyncTypeahead
                    ref={ typeahead }
                    delay={ 300 }
                    required={ props.required }
                    id={ props.id }
                    inputProps={{ className: props.className + " d-block mb-1 rounded-1" }}
                    placeholder={ `Enter your ${props.label}` }
                    isLoading={ isLoading }
                    filterBy={ ()=>true }
                    minLength={ 5 }
                    onSearch={ handleSearch }
                    options={ options }
                    labelKey={ 'label' }
                    onInputChange={()=>setIsLoading(true)}
                    onChange={ (selectedItems)=>{
                      if(selectedItems && selectedItems[0]) {
                        const newAddress = {}
                        ADDRESS_FORMATS[country].format.flat().forEach((entry)=>{
                          newAddress[entry] = selectedItems[0][entry]
                        })
                        newAddress.address_line_1 = [selectedItems[0].street, selectedItems[0].housenumber].filter(Boolean).join(' ')
                        setAddress(newAddress)
                      }
                      event.preventDefault()
                      setShowCompleteAddressForm(true)
                      if(typeahead?.current) {
                        typeahead.current.clear()
                        typeahead.current.blur()
                      }
                      return false
                    }}
                    renderMenu={(results, menuProps) => (
                      <Menu id={menuProps?.id}>
                        {isLoading && (
                          <div style={{ padding: '8px 16px', color: '#999' }}>
                            Searching...
                          </div>
                        )}
                        {!isLoading && results.map((result, index) => (
                          <MenuItem key={index} option={result} position={index}>
                            {result.label}
                          </MenuItem>
                        ))}
                        <MenuItem option={{label: 'Enter address manually'}} onClick={(event)=>{
                          event.preventDefault()
                          setShowCompleteAddressForm(true)
                          if(typeahead?.current) {
                            typeahead.current.clear()
                            typeahead.current.blur()
                          }
                          return false
                        }}>
                          <i className="far fa-edit me-2"></i><span>Enter address manually</span>
                        </MenuItem>
                      </Menu>
                    )}
                  />
                  <div className="ps-1 mb-2 position-relative">
                    <input required className="w-100 position-absolute" style={{ opacity: '0', top: 0, left: 0, right: 0, bottom: 0 }}/>
                    <button type="button" className="btn btn-sm btn-inline-link font-size-s position-relative" onClick={()=>{ setShowCompleteAddressForm(true) }}>
                      <i className="far fa-edit me-2"></i><span>Enter {props.label} manually</span>
                    </button>
                  </div>
                </div>
              </>
            }
            { country && showCompleteAddressForm &&
              <>
                { 
                  ADDRESS_FORMATS[country].format.map((line, lineIndex)=>{
                    if(typeof line == 'string') {
                      if(ADDRESS_FORMATS[country]?.suggestions && ADDRESS_FORMATS[country].suggestions[line]) {
                        return(
                          <div className='form-address-line' key={ lineIndex }>
                            <select value={ address[line] } required={ADDRESS_FORMATS[country].required.indexOf(line) > -1} required className="d-block form-control mb-1 rounded-1" placeholder={ addressFormatIdToLabel(line, country) } title={ addressFormatIdToLabel(line, country) } name={ line } id={ line }>
                              <option key={'default'} value=''>{addressFormatIdToLabel(line, country)}</option>
                              { ADDRESS_FORMATS[country].suggestions[line].map((suggestion, index)=>{
                                return(
                                  <option key={index} value={suggestion?.value ? suggestion.value : suggestion}>{suggestion?.label ? suggestion.label : suggestion}</option>
                                )
                              }) }
                            </select>
                          </div>
                        )
                      } else {
                        return(
                          <div className='form-address-line' key={ lineIndex }>
                            <input required={ADDRESS_FORMATS[country].required.indexOf(line) > -1} value={ address[line] } onChange={(event)=> setAddress({...address, [line]: event.target.value })} type="text" className="d-block form-control mb-1 rounded-1" placeholder={ addressFormatIdToLabel(line, country) } title={ addressFormatIdToLabel(line, country) } name={ line } id={ line }/>
                          </div>
                        )
                      }
                    } else if(line instanceof Array) {
                      return(
                        <div className="row form-address-line" key={ lineIndex }>
                          {
                            line.map((column, columIndex)=>{
                              return(
                                <div className="col form-address-column" key={ `${lineIndex}-${columIndex}` }>
                                  <input required={ADDRESS_FORMATS[country].required.indexOf(column) > -1} value={ address[column] } onChange={(event)=> setAddress({...address, [column]: event.target.value })} type="text" className="d-block form-control mb-1 rounded-1" placeholder={addressFormatIdToLabel(column, country) } title={addressFormatIdToLabel(column, country) } name={ column } id={ column }/>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  })
                }
              </>
            }
          </>
        }
      </div>
    </>
  )

}
