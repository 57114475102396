import React from "react"
import Token from "../models/Token"
import { TokenImage } from '@depay/react-token-image'
import { useSelector } from 'react-redux'

export default function(props) {
  
  const tokenData = useSelector(state => state.tokens.byId[`${props.blockchain}-${props.tokenId}`])
  
  if(tokenData == undefined){ return(null) }

  return(
    <span>
      <TokenImage
        className="token-image-inline bg-dark-grey"
        blockchain={ props.blockchain }
        address={ props.tokenId }
      />
      { tokenData.symbol } { props.amount }
    </span>
  )
}
