import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import Dialog from "../../components/Dialog"
import getInitials from "../../helpers/getInitials"
import LoadingText from "../../components/LoadingText"
import React, { useState, useEffect, useRef } from "react"
import { GET, POST } from '../../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const accountUsers = useSelector(state => state.account_users)
  const users = useSelector(state => state.users)
  const user = useSelector(state => state.user)
  const owner = useSelector(state => (state.users?.byId || {})[account?.owner_id])
  const [selectedUser, setSelectedUser] = useState()
  const [transferring, setTransferring] = useState(false)
  const [transferConfirmation, setTransferConfirmation] = useState()
  const inputElement = useRef()
  const navigate = useNavigate()

  useEffect(()=>{
    GET(`/api/accounts/${account.id}`).then((account)=>{
      Store.dispatch(({ type: 'account/change', account }))
    })
  }, [])

  useEffect(()=>{
    if(account) {
      GET(`/api/accounts/${account.id}/account_users`).then((accountUsers)=>{
        Store.dispatch({ type: 'accountUsers/set', accountUsers })
      })
      GET(`/api/accounts/${account.id}/users`).then((users)=>{
        Store.dispatch({ type: 'users/set', users })
      })
    }
  }, [account])

  const selectNewOwner = (user)=>{
    if(!user.name) {
      user = Object.assign(user, { name: 'User' })
    }
    setSelectedUser(user)
    setTimeout(()=>{ inputElement.current.focus() }, 200)
  }

  const transfer = (user)=>{
    setTransferring(true)
    POST(`/api/accounts/${account.id}/transfer`, {
      user_id: user.id
    }).then(()=>{
      navigate('/settings')
    }).catch(()=>{
      setTransferring(false)
    })
  }

  return(
    <div className="account-owner-transfer-screen row">

      <Dialog
        open={ !!selectedUser }
        close={ ()=>{ 
          if(!transferring) {
            setSelectedUser()
            setTransferConfirmation()
          }
        }}
        header={ 'Ownership Transfer' }
        body={
          <div>
            <div className="card d-block p-0 border border-danger">
              <div className="p-2 py-3 p-lg-3">
                <div className="d-flex align-items-center">
                  <div className="ps-1">
                    <i className="fa-solid fa-triangle-exclamation text-danger"></i>
                  </div>
                  <div className="flex-grow-1 line-height-extra-small px-3 text-danger">
                    Ownership transfers are irreversible!
                  </div>
                </div>
              </div>
            </div>
            <div className="card d-block line-height-extra-small p-2">
              <div className="p-1">
                <p className="font-size-s">Please type the name of the user you want to transfer the account to: <strong>{ selectedUser?.name }</strong></p>
              </div>
              <input
                required
                disabled={transferring}
                ref={inputElement}
                className="form-control w-100"
                type="text"
                onChange={ (event)=>setTransferConfirmation(event.target.value) }
                placeholder={selectedUser?.name}
              />
            </div>
          </div>
        }
        footer={
          <div className="pb-3">
            <button type="button" disabled={!(selectedUser?.name && transferConfirmation === selectedUser?.name)} className="btn btn-danger btn-lg rounded-1 w-100" onClick={()=>{
              if(selectedUser?.name && transferConfirmation === selectedUser?.name){
                transfer(selectedUser)
              }
            }}>
              { transferring && <LoadingText>Transfering</LoadingText> }
              { !transferring && <span>Transfer</span> }
            </button>
          </div>
        }
      />

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0">

        <div className="col-lg-7">

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="accountName" className="pb-1">
              <h5>Transfer Account Ownership</h5>
            </label>
          </div>

          <div className="card d-block p-0 border border-danger">
            <div className="p-2 py-3 p-lg-3">
              <div className="d-flex align-items-center">
                <div className="ps-1">
                  <i className="fa-solid fa-triangle-exclamation text-danger"></i>
                </div>
                <div className="flex-grow-1 line-height-extra-small px-3 text-danger">
                  Ownership transfers are irreversible!
                </div>
              </div>
            </div>
          </div>

          <div className="pt-2 pb-4">
            <div className="card d-block p-0">
              { accountUsers.map((accountUser, index)=>{
                const user = users?.byId ? users.byId[accountUser.user_id] : undefined
                const userName = user?.name || accountUser.name
                if(!user){ return }
                if(user.id === account.owner_id){ return }
                return(
                  <div key={index} className="pt-1">
                    <div className="p-2 py-3 p-lg-3">
                      <div className="d-flex align-items-center">
                        <div className="ps-2">
                          { user.image &&
                            <div className="circular-icon me-1" style={{ left: '2px', backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(https://uploads.depay.com/${user.image})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                          }

                          { !user.image &&
                            <div className="circular-icon me-1">
                              { userName && <span className="text-white">{ getInitials(userName) }</span> }
                              { !userName && <i className="fa-regular fa-user text-white"></i> }
                            </div>
                          }
                        </div>
                        <div className="flex-grow-1 line-height-extra-small px-3">
                          <div>
                            <span className="font-weight-medium">{ userName || 'User' }</span>
                          </div>
                        </div>
                        <div className="text-end">
                          <button type="button" onClick={()=>selectNewOwner(user)} className="btn btn-action">Select</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) }
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fas fa-info-circle me-2"></i>
                <span>You can only transfer ownership to existing team members: </span>
                <NavLink to="/settings/team">Invite new team member</NavLink>
              </small>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
