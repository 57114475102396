import AccountSettingsScreen from "./screens/settings/Account"
import AccountTokens from "./screens/settings/Tokens"
import AddUserWallet from "./screens/user/AddUserWallet"
import AddWalletSettings from "./screens/settings/AddWallet"
import ApiScreen from "./screens/Api"
import AppsScreen from "./screens/Apps"
import ReceiptsScreen from "./screens/Receipts"
import BillingSettings from "./screens/settings/Billing"
import BrandingSettings from "./screens/settings/Branding"
import EditIntegrationScreen from "./screens/integrations/Edit"
import EditLinkScreen from "./screens/links/Edit"
import EditRequestScreen from "./screens/requests/Edit"
import EditTeam from "./screens/settings/EditTeam"
import EditUserWallet from "./screens/user/EditUserWallet"
import EditWalletSettings from "./screens/settings/EditWallet"
import HeaderComponent from "./components/Header"
import IntegrationOverviewScreen from "./screens/integrations/Overview"
import IntegrationsScreen from "./screens/Integrations"
import InviteTeam from "./screens/settings/InviteTeam"
import LinksScreen from "./screens/Links"
import LoginWallScreen from "./screens/LoginWall"
import NewAppScreen from "./screens/apps/New"
import OverviewLinkScreen from "./screens/links/Overview"
import OverviewScreen from "./screens/Overview"
import PaymentsScreen from "./screens/Payments"
import PlanScreen from "./screens/Plan"
import PluginsScreen from "./screens/Plugins"
import React, { useState, useEffect } from "react"
import RequestsScreen from "./screens/Requests"
import ShowAppScreen from "./screens/apps/Show"
import SidebarComponent from "./components/Sidebar"
import switchAccount from './helpers/switchAccount'
import TeamSettings from "./screens/settings/Team"
import TransferOwner from "./screens/settings/TransferOwner"
import User2FA from "./screens/user/2FA"
import UserAccounts from "./screens/user/Accounts"
import UserAuthentication from "./screens/user/Authentication"
import UserSettings from "./screens/user/Settings"
import WalletsSettings from "./screens/settings/Wallets"
import { GET } from './helpers/Queries'
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { Store } from './Store'
import { useSelector } from 'react-redux'

export default function(props){

  const location = useLocation()
  const searchParams = location && location.search ? new URLSearchParams(location.search) : undefined
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const accounts = useSelector(state => state.accounts)
  const [ openNavigation, setOpenNavigation ] = useState(false)

  useEffect(()=>{
    if(user) {
      GET('/api/accounts').then((accounts)=>{
        Store.dispatch({ type: 'accounts/set', accounts })
      })
    }
  }, [user])

  useEffect(()=> {
    if(openNavigation) {
      let handleClick = ()=>setOpenNavigation(false)
      setTimeout(()=>{ document.body.addEventListener('click', handleClick) }, 100)
      return ()=>document.body.removeEventListener('click', handleClick)
    }
  }, [openNavigation])

  useEffect(()=>{
    const accountId = searchParams ? searchParams.get('account') : undefined
    if(accountId) {
      const account = accounts.find((account)=>account.id === accountId)
      if(account) {
        switchAccount(account).then(()=>{
          searchParams.delete('account')
          navigate(`${location.pathname}?${searchParams.toString()}`)
          window.location.reload(true)
        })
      }
    }
  }, [location, searchParams, accounts])

  useEffect(()=>{
    if(user){
      document.title = "DePay"
    }
  }, [user])

  if(user == undefined) { return(<LoginWallScreen/>) }

  if(searchParams && searchParams.get('account')) { return null }

  return(
    <div>
      
      <div className="light"/>

      <div className="container position-relative">

        <div className="row">

          <SidebarComponent open={ openNavigation }/>

          <div className="col stick-sidekiq">

            <div className="screens">

              <Routes>
                <Route path="/" element={ <OverviewScreen /> } />

                <Route path="/plan" element={ <PlanScreen /> } />
                
                <Route path="/receipts" element={ <ReceiptsScreen /> } />

                <Route path="/payments" element={ <PaymentsScreen /> } />
                <Route path="/payments/:id" element={ <PaymentsScreen /> } />

                <Route path="/requests" element={ <RequestsScreen /> } />
                <Route path="/requests/:id/edit" element={ <EditRequestScreen /> } />

                <Route path="/links" element={ <LinksScreen /> } />
                <Route path="/links/:id/edit" element={ <EditLinkScreen /> } />
                <Route path="/links/:id/overview" element={ <OverviewLinkScreen /> } />
                <Route path="/links/:id/overview/:payment_id" element={ <OverviewLinkScreen /> } />

                <Route path="/plugins" element={ <PluginsScreen /> } />
                
                <Route path="/settings" element={ <AccountSettingsScreen /> } />
                <Route path="/settings/wallets" element={ <WalletsSettings /> } />
                <Route path="/settings/wallets/:id" element={ <EditWalletSettings /> } />
                <Route path="/settings/wallets/new" element={ <AddWalletSettings /> } />
                <Route path="/settings/branding" element={ <BrandingSettings /> } />
                <Route path="/settings/team" element={ <TeamSettings /> } />
                <Route path="/settings/team/invite" element={ <InviteTeam /> } />
                <Route path="/settings/team/:id" element={ <EditTeam /> } />
                <Route path="/settings/tokens" element={ <AccountTokens /> } />
                <Route path="/settings/owner/transfer" element={ <TransferOwner /> } />
                <Route path="/settings/billing" element={ <BillingSettings /> } />
                
                <Route path="/dev/integrations" element={ <IntegrationsScreen /> } />
                <Route path="/dev/integrations/:id/edit" element={ <EditIntegrationScreen /> } />
                <Route path="/dev/integrations/:id/overview" element={ <IntegrationOverviewScreen /> } />
                <Route path="/dev/integrations/:id/overview/:payment_id" element={ <IntegrationOverviewScreen /> } />

                <Route path="/dev/apps" element={ <AppsScreen /> } />
                <Route path="/dev/apps/new" element={ <NewAppScreen /> } />
                <Route path="/dev/apps/:id" element={ <ShowAppScreen /> } />
                
                <Route path="/dev/api" element={ <ApiScreen /> } />

                <Route path="/user/settings" element={ <UserSettings /> } />
                <Route path="/user/accounts" element={ <UserAccounts /> } />
                <Route path="/user/authentication" element={ <UserAuthentication /> } />
                <Route path="/user/authentication/wallets/new" element={ <AddUserWallet /> } />
                <Route path="/user/authentication/wallets/:id" element={ <EditUserWallet /> } />
                <Route path="/user/authentication/2fa" element={ <User2FA /> } />
              </Routes>
            </div>
          </div>

        </div>
        
      </div>

      <HeaderComponent openNavigation={ openNavigation } toggleNavigation={ ()=>{ setOpenNavigation(!openNavigation) } }/>

    </div>
  )
}
