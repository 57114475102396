import React, { useState, useEffect } from "react"
import { FileUploader } from "react-drag-drop-files"
import { GET } from '../helpers/Queries'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const [ preview, setPreview ] = useState()

  const createPresignedUpload = ()=>{
    return new Promise((resolve, reject)=>{
      fetch(`/api/accounts/${account.id}/uploads`, {
        method: 'POST',
        body: JSON.stringify({ type: props.name }),
        headers: { "Content-Type": "application/json", "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content }
      }).then((response)=>{
        response.json().then((data)=>{
          resolve(data)
        })
      })
    })
  }
  
  const loadAndSetPreview = (key)=>{
    GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
      setPreview(presigned_url)
    })
  }

  const upload = async(file)=>{
    if(props.onUploadStart){ props.onUploadStart(file) }
    let presignedUpload = await createPresignedUpload()
    let formData = new FormData()
    let key = presignedUpload.key_starts_with+"/"+escape(
      file.name.replace(/[^A-Za-z0-9\-_\.!~\*'\(\)]/g, '-').replaceAll(/--+/g, '-')
    )
    formData.append('key', key)
    Object.keys(presignedUpload.fields).forEach((key)=>{
      formData.append(key, presignedUpload.fields[key])
    })
    formData.append('Content-Type', file.type)
    formData.append('file', file)

    fetch(presignedUpload.url, {
      method: 'POST',
      body: formData
    }).then((response)=>{
      if(response.ok && props.onChange) {
        props.onChange(key)
        if(props.preview === true) { loadAndSetPreview(key) }
      }
    }).catch((error)=>{
      console.log('UPLOADING ERROR', error)
    })
  }

  return(
    <div className={['file-uploader', props.className].filter(Boolean).join(' ')} style={ props.style }>
      { preview &&
        <div className='file-uploader-preview'>
          <img src={ preview } style={ props.previewStyle }/>
        </div>
      }
      <div className={ preview ? 'file-uploader-inner file-uploader-inner-has-preview' : 'file-uploader-inner' }>
        <FileUploader
          label={ props.label || "Click or drop an image (max. 1 MB)" }
          handleChange={ upload }
          name={ props.name }
          types={ props.types }
          maxSize={ 1 }
        />
      </div>
    </div>
  )
}
