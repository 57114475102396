import copy from 'copy-to-clipboard'
import Dropdown from '../../components/Dropdown'
import groupPayments from '../../helpers/groupPayments'
import InteractiveRangeChart from '../../components/InteractiveRangeChart'
import loadAdditionalTokenDataForPayments from "../../helpers/loadAdditionalTokenDataForPayments"
import moment from 'moment'
import Payment from "../../models/Payment"
import PaymentDetails from '../../components/payments/Details'
import PaymentList from '../../components/payments/List'
import React, { useState, useEffect, useRef } from "react"
import { GET } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

const DOCUMENTATION_LINKS = {
  payment: {
    widget: 'https://depay.com/docs/payments/integrate/widget',
    button: 'https://depay.com/docs/payments/integrate/button',
  },
  donation: {
    widget: 'https://depay.com/docs/payments/donations/widget',
    button: 'https://depay.com/docs/payments/donations/button',
  },
  tip: {
    widget: 'https://depay.com/docs/payments/tips/widget',
    button: 'https://depay.com/docs/payments/tips/button',
  }
}

export default function(props) {

  const account = useSelector(state => state.account)
  const { id: selectedIntegrationId } = useParams()
  const payments = useSelector(state => state.payments_for_integration ? state.payments_for_integration[selectedIntegrationId] : null)
  const integration = useSelector(state => state.integrations.find((integartion)=>integartion.id===selectedIntegrationId))
  const statistic = useSelector(state => state?.statistics?.integration?.[integration.id])
  const accountWallets = useSelector(state => state.account_wallets)
  const [ hasMore, setHasMore ] = useState(true)
  const [ statisticsAvailable, setStatisticsAvailable ] = useState(false)
  const [ statisticsLoaded, setStatisticsLoaded ] = useState(false)
  const [ paymentsLoaded, setPaymentsLoaded ] = useState(false)
  const navigate = useNavigate()
  const { payment_id: selectedPaymentId } = useParams()
  let [ selectedPayment, setSelectedPayment ] = useState()

  const loadMore = ()=>{
    if(payments.groups.length == 0) { return }
    const oldestGroup = payments.byGroupId[payments.groups[payments.groups.length-1]]
    const oldestPayment = payments.byId[oldestGroup[oldestGroup.length-1]]
    GET(`/api/accounts/${account.id}/payments?wallets=&links=&integrations=${integration.external_id}&after=${oldestPayment.confirmed_at}`).then((paymentsAfter)=>{
      if(paymentsAfter.length == 0){
        setHasMore(false)
      } else {
        loadAdditionalTokenDataForPayments(paymentsAfter)
        Store.dispatch(({ type: 'payments/integrations/add', id: integration.id, payments: paymentsAfter }))
      }
    })
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/integrations`).then((integrations)=>{
      Store.dispatch({ type: 'integrations/set', integrations })
    })
  }, [])

  useEffect(()=>{

    const now = moment()
    const range = `${now.year()}-${now.format("MM")}`
    Promise.all([
      GET(`/api/accounts/${account.id}/statistics/integration/${integration.id}/payments/total`),
      GET(`/api/accounts/${account.id}/statistics/integration/${integration.id}/volume/total`),
      GET(`/api/accounts/${account.id}/statistics/integration/${integration.id}/payments/${range}`),
      GET(`/api/accounts/${account.id}/statistics/integration/${integration.id}/volume/${range}`),
    ]).then(([totalPaymentsStatistic, totalVolumeStatistic, monthlyPaymentsStatistic, monthlyVolumeStatistic])=>{
      Store.dispatch({ type: 'statistics/set', belongsTo: 'integration', id: integration.id, name: 'payments', range: 'total', value: totalPaymentsStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'integration', id: integration.id, name: 'volume', range: 'total', value: totalVolumeStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'integration', id: integration.id, name: 'payments', range: range, value: monthlyPaymentsStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'integration', id: integration.id, name: 'volume', range: range, value: monthlyVolumeStatistic })
      setStatisticsAvailable(!!(totalPaymentsStatistic && totalVolumeStatistic))
      setStatisticsLoaded(true)
    })

    GET(`/api/accounts/${account.id}/payments?wallets=&links=&integrations=${integration.external_id}`).then((payments)=>{
      if(payments && payments.length > 0) {
        loadAdditionalTokenDataForPayments(payments)
        Store.dispatch(({ type: 'payments/integration/set', id: integration.id, payments }))
      }
      setPaymentsLoaded(true)
    }).catch(()=>{ setPaymentsLoaded(true) })

  }, [integration])

  useEffect(()=>{
    if(accountWallets && accountWallets.length && selectedPaymentId && payments && payments.byId[selectedPaymentId]) {
      setSelectedPayment(new Payment(payments.byId[selectedPaymentId], accountWallets))
    } else {
      setSelectedPayment()
    }
  }, [accountWallets, selectedPaymentId, payments])

  if(!integration || !statisticsLoaded || !paymentsLoaded) {
    return(
      <div className="integration-overview-screen px-3 pt-5 pe-lg-0">
        
        <div className="pt-3 pt-lg-0">

          <div className="row pt-3">
            <div className="col-12">
              <div className="card skeleton d-inline-block me-3" style={{ width: '58px' }}>
                <div style={{ height: '3rem', width: '58px' }}/>
              </div>
            </div>
          </div>

          <div className="pt-4">

            <div className="row">

              <div className="col-12 col-md-6">
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 ps-md-4">

                <div className="card p-4 row skeleton">
                  <div style={{ height: '36rem' }} className="w-100"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className="integration-overview-screen px-3 pt-5 pe-lg-0">

      <div className="pt-3 pt-lg-0">

        <div className="row pt-3 pb-3">

          <div className="col-12">
            <div className="d-flex flex-row position-relative align-items-center" style={{ height: '3.6rem' }}>

              <div>

                <h2 className="pe-4">
                  <strong>{ integration.name || 'Integration' }</strong>
                </h2>

                <div className="position-absolute pt-2" style={{ top: 0, right: 0 }}>
                  <Dropdown
                    toggle={
                      <button className="btn btn-action-hover mt-1" type="button">
                        <i className="fas fa-ellipsis-v text-black-50"></i>
                      </button>
                    }
                    children={[
                      ['Edit', ()=>{ navigate(`/dev/integrations/${integration.id}/edit`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                    ]}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="row pt-3">

          <div className="col-12 col-md-6 order-1 order-md-0">

            { !payments &&
              <div>
                <h5 className="pb-2 pt-2">No payments, yet</h5>
                <div>
                  <p className="text-black-50 mb-1">
                    Finish integration, and effortlessly start collecting payments:
                  </p>
                </div>
                <div className="pt-2 pb-4">
                  <div className="col-12 col-md-6 pt-2"><a className="btn btn-action w-100 text-start" href={`/dev/integrations/${integration.id}/edit`}><i className="far fa-pen-to-square me-2"></i>Edit Integration</a></div>
                  <div className="col-12 col-md-6 pt-2"><a className="btn btn-action w-100 text-start" href={DOCUMENTATION_LINKS[integration.type][integration.form]} target="_blank" rel="noopener noreferrer"><i className="fas fa-book me-2"></i>Read Documentation</a></div>
                </div>
              </div>
            }

            { payments &&

              <PaymentList
                payments={ payments }
                link={ (id)=>(`/dev/integrations/${selectedIntegrationId}/overview/${id}`) }
                loadMore={ loadMore }
                hasMore={ hasMore }
              />
            }
          </div>

          <div className="col-12 col-md-6 ps-md-3 order-0 order-md-1 sticky-wrapper">
            <div className="sticky-item px-0 px-md-3 pb-2 pb-md-4" style={{'--offset': '100px'}}>
              {
                selectedPaymentId !== undefined &&
                selectedPayment &&
                  <div className="mobile-sticky-overlay pb-4">
                    <div className="px-3 px-lg-0">
                      <PaymentDetails payment={ selectedPayment } linkBack={`/dev/integrations/${integration.id}/overview`}/>
                    </div>
                  </div>
              }
              <div className="card row pt-2 pt-md-3 pb-2 pb-md-3">
                <div>
                  {
                    !statisticsAvailable &&
                    <div className="text-center pb-4 pt-3">
                      <div className="pt-1 text-center m-auto w-50">
                        <div className="mascot mascot-not-found d-inline-block"></div>
                      </div>
                      <div className="font-size-l text-center">No statistics found</div>
                    </div>
                  }

                  {
                    statisticsAvailable &&
                    <div className="row">
                      <div className="col-12 p-2 pb-md-3 p-md-3">
                        <InteractiveRangeChart belongsTo={ 'integration' } belongsToId={ integration.id } label={ 'Volume' } name={ 'volume' } format={ 'currency' }/>
                      </div>
                      <div className="col-12 p-2 pb-md-3 p-md-3">
                        <InteractiveRangeChart belongsTo={ 'integration' } belongsToId={ integration.id } label={ 'Payments' } name={ 'payments' }/>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
