import AddressEllipsis from "../../helpers/AddressEllipsis"
import CloseIcon from "../../components/CloseIcon"
import DePayWidgets from "@depay/widgets"
import Dropdown from '../../components/Dropdown'
import moment from 'moment'
import React, { useEffect, useState, useRef, useMemo } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { GET, POST } from '../../helpers/Queries'
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom"
import { Store } from '../../Store'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useSelector } from "react-redux"

export default function(props) {

  const allWalletsSorted = useMemo(()=>{
    let allWalletsSorted = DePayWidgets.allWallets.map((wallet)=>({ label: wallet.name, value: wallet.name })).sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      } else if (a.label < b.label) {
        return -1;
      } else {
        return 0;
      }
    })
    allWalletsSorted.unshift({ label: 'Other', value: 'Other' })
    return allWalletsSorted
  }, [])

  const location = useLocation()
  const account = useSelector(state => state.account)
  const walletTypeElement = useRef()
  const accountWallets = useSelector(state => state.account_wallets)
  let [ verifyData, setVerifyData ] = useState(false)
  let [ newName, setNewName ] = useState()
  let [ newAddress, setNewAddress ] = useState()
  let [ adding, setAdding ] = useState()
  let [ selectedWalletType, setSelectedWalletType ] = useState()
  let [ showError, setShowError ] = useState()
  let [ wallet, setWallet ] = useState()
  const { id: selectedWalletId, connect } = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    if(location && location.search) {
      const searchParams = new URLSearchParams(location.search)
      if(!!searchParams.get('connect')) {
        DePayWidgets.Connect({}).then((connected)=>{
          setWallet(connected.wallet)
          setSelectedWalletType(allWalletsSorted.find((wallet)=>wallet.value === connected.wallet.name))
          setNewName(connected.wallet.name)
          setNewAddress(connected.account)
          setVerifyData(true)
        }).catch(()=>{
          setVerifyData(true)
        })
      } else {
        setVerifyData(true)
      }
    } else {
      setVerifyData(true)
    }
  }, [location])

  const saveNewWallet = (e)=>{
    e.preventDefault()
    
    if(!!accountWallets.find((wallet)=>{
      return wallet.external_id === newAddress
    })){
      setShowError('Wallet already connected!')
      return
    }
    setShowError()
    setAdding(true)
    let signingAccount
    DePayWidgets.Login({ wallet, message: (account)=>{
      signingAccount = account
      let dateTime = moment().format()
      return `Click to add wallet to DePay and to accept DePay's Terms of Service: https://depay.com/legal/terms\n\n${dateTime}\n${account}`
    }, recover: ({ message, signature })=> {
      POST(`/api/accounts/${account.id}/account_wallets`, {
        message,
        signature,
        external_id: newAddress,
        key: signingAccount,
        name: newName,
        wallet_type: selectedWalletType.value
      }).then((accountWallet)=>{
        Store.dispatch({ type: 'accountWallets/add', accountWallet })
        setAdding(false)
        setNewName()
        setNewAddress()
        navigate('/settings/wallets')     
      }).catch((error)=>{
        setShowError('Adding wallet failed!')
        setAdding(false)
      })
      return Promise.resolve(true)
    }}).catch(()=>{
      setAdding(false)
    })

    return false
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
    })
  }, [])

  return(

    <div className="wallets-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-7 col-xl-6 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0">

        <h5 className="pt-3 ps-1 pb-1 mb-1">Add Account Wallet</h5>

        {
          verifyData &&
          <form onSubmit={ saveNewWallet } className="bg-grey shadow rounded d-block px-3 pb-3">
            <div className="row pt-3 pb-1 mb-1">
              <label>
                <span className='d-block pb-1 opacity-50'>
                  Wallet Type
                </span>
                <Typeahead
                  autocomplete={'off'}
                  selected={ [selectedWalletType].filter(Boolean) }
                  ref={ walletTypeElement }
                  required={ true }
                  id={ 'walletType' }
                  placeholder={ "Select which wallet" }
                  multiple={ false }
                  highlightOnlyResult={ true }
                  inputProps={{ className: " d-block mb-1 py-2 px-2 rounded-1 bg-white border form-control" }}
                  onChange={(selection) => {
                    setSelectedWalletType(selection[0])
                  }}
                  options={ allWalletsSorted }
                />
              </label>
            </div>
            <div className="row pt-1 pb-1">
              <label>
                <span className='d-block pb-1 opacity-50'>
                  Wallet Name
                </span>
                <input required="required" placeholder="Payment wallet" type="text" className="row bg-white rounded-1 mb-2 p-2 px-2 form-control" value={ newName || '' }
                  onChange={(event)=>{ setNewName(event.target.value) }}
                />
              </label>
            </div>
            <div className="row pt-1">
              <label>
                <span className='d-block pb-1 opacity-50'>
                  Wallet Address
                </span>
                <input required="required" placeholder="0xd8da6bf26964af9d7eed9e03e53415d37aa96045" type="text" className="row bg-white rounded-1 mb-2 p-2 px-2 form-control form-control-sm" value={ newAddress || '' }
                  onChange={(event)=>{ setNewAddress(event.target.value) }}
                />
              </label>
            </div>
            <div className="row pt-2">
              { showError &&
                <div className="alert alert-danger py-2">
                  { showError }
                </div>
              }
              <button type="submit" className="btn btn-primary btn-md rounded-2 mt-1 w-100" disabled={ adding }>
                Verify & Add
              </button>
            </div>
          </form>
        }

      </div>
    </div>
  )
}
