import React from "react"

export default function(props) {

  return(

    <div className="plugins-screen px-3 pt-3">

      <div className="row pt-5 pb-5">
        <div className="col-12 col-xl-6">
          
          <div className="pt-2">
            <div className="card d-block px-3 pb-3">
              <div className="row border-bottom pb-3">
                <a href="https://apps.shopify.com/depay" target="_blank" className="d-inline-block rounded-1 plugin-button-background shopify"></a>
                <div className="col-7 ps-3">
                  <div className="line-height-extra-small pt-1">
                    <span className="font-weight-medium">
                      Shopify
                    </span>
                  </div>
                  <div>
                    <small><a href="https://depay.com/how-to/accept-web3-cryptocurrency-payments-on-shopify-4yObn75fWUVaxzBDbN8Dzd" className="link-light" target="_blank">
                      How-to Guide
                      <i className="fas ms-2 fa-external-link-alt"></i>
                    </a></small>
                  </div>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  <a className="btn btn-action btn-sm" href="https://apps.shopify.com/depay" target="_blank">Install</a>
                </div>
              </div>

              <div className="row border-bottom pb-3 pt-3">
                <a href="https://woocommerce.com/products/depay-payments/" target="_blank" className="d-inline-block rounded-1 plugin-button-background woocommerce"></a>
                <div className="col-7 ps-3">
                  <div className="line-height-extra-small pt-1">
                    <span className="font-weight-medium">
                      WooCommerce
                    </span>
                  </div>
                  <div>
                    <small><a href="https://depay.com/how-to/accept-web3-cryptocurrency-payments-on-woocommerce-4tbR4B1bdmipZ5a2N1e8HJ" className="link-light" target="_blank">
                      How-to Guide
                      <i className="fas ms-2 fa-external-link-alt"></i>
                    </a></small>
                  </div>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  <a className="btn btn-action btn-sm" href="https://woocommerce.com/products/depay-payments/" target="_blank">Install</a>
                </div>
              </div>

              <div className="row border-bottom pt-3 pb-1">
                <a href="https://wordpress.org/plugins/depay-payments/" target="_blank" className="d-inline-block rounded-1 plugin-button-background wordpress"></a>
                <div className="col-7 ps-3">
                  <div className="line-height-extra-small pt-1">
                    <span className="font-weight-medium">
                      Wordpress Payments
                    </span>
                  </div>
                </div>
                <div className="col d-flex align-items-center justify-content-end pb-3">
                  <a className="btn btn-action btn-sm" href="https://wordpress.org/plugins/depay-payments/" target="_blank">Install</a>
                </div>
              </div>

              <div className="row pt-3">
                <a href="https://wordpress.org/plugins/depay-donations/" target="_blank" className="d-inline-block rounded-1 plugin-button-background wordpress"></a>
                <div className="col-7 ps-3">
                  <div className="line-height-extra-small pt-1">
                    <span className="font-weight-medium">
                      Wordpress Donations
                    </span>
                  </div>
                  <div>
                    <small><a href="https://depay.com/how-to/accept-web3-cryptocurrency-donations-on-wordpress-3kmut5La6fMFiv7lHCfaeF" className="link-light" target="_blank">
                      How-to Guide
                      <i className="fas ms-2 fa-external-link-alt"></i>
                    </a></small>
                  </div>
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  <a className="btn btn-action btn-sm" href="https://wordpress.org/plugins/depay-donations/" target="_blank">Install</a>
                </div>
              </div>
            </div>

            <div className="d-block w-100 text-center px-5 pb-4">
              <div className="">
                <div className="pb-1 pt-1 text-center text-sm-start">
                  <div className="mascot mascot-plugin d-inline-block d-sm-block"></div>
                </div>
                <div className="font-size-l text-center text-sm-start text-lg-center">Just use a plugin</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
