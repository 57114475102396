import AddressEllipsis from "../../helpers/AddressEllipsis"
import CloseIcon from "../../components/CloseIcon"
import DePayWidgets from "@depay/widgets"
import Dropdown from '../../components/Dropdown'
import moment from 'moment'
import React, { useEffect, useState } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import WalletIcon from "../../components/WalletIcon"
import { GET, POST, DELETE } from '../../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { Store } from '../../Store'
import { useSelector } from "react-redux"

export default function(props) {

  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  let [ selectedWallet, setSelectedWallet ] = useState()
  let [ newName, setNewName ] = useState()
  let [ newAddress, setNewAddress ] = useState()
  let [ adding, setAdding ] = useState()
  let [ showError, setShowError ] = useState()
  const navigate = useNavigate()

  const saveSelectedWallet = ()=>{
    Store.dispatch({ type: 'accountWallets/update', accountWallet: selectedWallet })
    POST(`/api/accounts/${account.id}/account_wallets/${selectedWallet.id}`, selectedWallet)
    navigate('/wallets')
  }

  const deleteWallet = (accountWallet)=>{
    let confirmation = confirm(`Are you sure you want to delete ${accountWallet.name || 'wallet'}?`)
    if(confirmation) {
      Store.dispatch({ type: 'accountWallets/delete', id: accountWallet.id })
      DELETE(`/api/accounts/${account.id}/account_wallets/${accountWallet.id}`)
    }
  }

  const saveNewWallet = ()=>{
    
    if(!!accountWallets.find((wallet)=>{
      return wallet.external_id == newAddress
    })){
      setShowError('Wallet already connected!')
      return
    }
    setShowError()
    setAdding(true)
    let signingAccount
    DePayWidgets.Login({ message: (account)=>{
      signingAccount = account
      let dateTime = moment().format()
      return `Click to add wallet to DePay and to accept DePay's Terms of Service: https://depay.com/legal/terms\n\n${dateTime}\n${account}`
    }, recover: ({ message, signature })=> {
      POST(`/api/accounts/${account.id}/account_wallets`, {
        message,
        signature,
        external_id: newAddress,
        key: signingAccount,
        name: newName
      }).then((accountWallet)=>{
        Store.dispatch({ type: 'accountWallets/add', accountWallet })
        setAdding(false)
        setNewName()
        setNewAddress()
        navigate('/wallets')     
      }).catch((error)=>{
        setShowError('Adding wallet failed!')
        setAdding(false)
      })
      return Promise.resolve(true)
    }}).catch(()=>{
      setAdding(false)
    })
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
    })
  }, [])

  let accountWalletsElement
  if(accountWallets && accountWallets.length) {
    accountWalletsElement = accountWallets.map((accountWallet, index)=>{
      return(
        <div key={accountWallet.id}>
          <NavLink to={`/settings/wallets/${accountWallet.id}`} key={accountWallet.id} className="circular-card-interactive p-2 py-3 p-lg-3">
            <div className="d-flex align-items-center">
              <div className="ps-2">
                <WalletIcon wallet={ accountWallet } size={ 34 }/>
              </div>
              <div className="flex-grow-1 line-height-extra-small px-3">
                <div>
                  <span className="font-weight-medium">{ accountWallet.name || "Unnamed Wallet" }</span>
                </div>
                <div>
                  <small className="font-weight-medium opacity-50" title={ accountWallet.external_id }>{ AddressEllipsis(accountWallet.external_id) }</small>
                </div>
              </div>
              <div className="text-end">
                <Dropdown
                  toggle={
                    <button className="btn btn-action-hover" type="button">
                      <i className="fas fa-ellipsis-h text-black-50"></i>
                    </button>
                  }
                  children={[
                    ['Edit', ()=>{ navigate(`/settings/wallets/${accountWallet.id}`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                    ['Delete', ()=>{ deleteWallet(accountWallet) }, <i className="far fa-trash-alt me-2"></i>],
                  ]}
                />
              </div>
            </div>
          </NavLink>
        </div>
      )
    })
  } else {
    accountWalletsElement = (
      <div className="d-block w-100 text-center px-5 pb-4">
        <div className="">
          <div className="pb-2 pt-1 text-center text-sm-start">
            <div className="mascot mascot-wallet d-inline-block d-sm-block"></div>
          </div>
          <div className="font-size-l pt-2 text-center text-sm-start text-lg-center">Add your first wallet</div>
        </div>
      </div>
    )
  }

  return(

    <div className="wallets-screen row">

      <div className="col-12 col-md-3 px-2 px-lg-0">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-7 col-xl-6 pt-2 pt-md-5 px-2 ps-md-3 pr-md-0">

        <div className="pt-3">
          <Dropdown
            toggle={
              <button type="button" className="btn btn-action btn-sm" title="Add additional wallet">
                <i className="fa-solid fa-plus me-2"></i>
                Add Wallet
              </button>
            }
            children={[
              ['Connect wallet', ()=>{ navigate(`/settings/wallets/new?connect=1`) }, <i className="fas fa-wallet me-2"></i>],
              ['Enter manually', ()=>{ navigate(`/settings/wallets/new`) }, <i className="far fa-pen-to-square me-2"></i>],
            ]}
          />
        </div>

        <div className="pt-2">
          <div className="card d-block p-0">
            { accountWalletsElement }
          </div>
          <div className="px-2 pt-1">
            <small className="text-muted ps-1">
              <i className="fas fa-info-circle me-2"></i>
              Payments for connected wallets will show up in your account.
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
