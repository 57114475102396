import AcceptedPayments from '../../components/integrations/edit/AcceptedPayments'
import CodeCard from '../../components/integrations/edit/CodeCard'
import InstallationCard from '../../components/integrations/edit/InstallationCard'
import IntegrationLogsCard from '../../components/integrations/edit/IntegrationLogsCard'
import isValidAddress from '../../helpers/isValidAddress'
import isValidHttpsUrl from '../../helpers/HttpsUrlValidator'
import isValidPublicKey from '../../helpers/PublicKeyValidator'
import PublicKey from '../../components/PublicKey'
import React, { useState, useEffect, useRef } from "react"
import { all } from '../../helpers/Currencies'
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { TokenImage } from '@depay/react-token-image'
import { useDebounce } from 'use-debounce'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

const DOCUMENTATION_LINKS = {
  payment: {
    widget: 'https://depay.com/docs/payments/integrate/widget',
    button: 'https://depay.com/docs/payments/integrate/button',
  },
  donation: {
    widget: 'https://depay.com/docs/payments/donations/widget',
    button: 'https://depay.com/docs/payments/donations/button',
  },
  tip: {
    widget: 'https://depay.com/docs/payments/tips/widget',
    button: 'https://depay.com/docs/payments/tips/button',
  }
}

export default function(props) {

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const integrations = useSelector(state => state.integrations)
  const integrationLogs = useSelector(state => state.integration_logs[id])
  const [ showTab, setShowTab ] = useState(integrationLogs?.length ? 'logs' : 'code')
  const [ callbackUrl, setCallbackUrl ] = useState('')
  const [ callbackUrlInvalid, setCallbackUrlInvalid ] = useState(false)
  const [ eventUrl, setEventUrl ] = useState('')
  const [ eventUrlInvalid, setEventUrlInvalid ] = useState(false)
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ redirectUrlInvalid, setRedirectUrlInvalid ] = useState(false)
  const [ dynamicUrl, setDynamicUrl ] = useState('')
  const [ dynamicUrlPublicKey, setDynamicUrlPublicKey ] = useState('')
  const [ dynamicUrlInvalid, setDynamicUrlInvalid ] = useState(false)
  const [ dynamicUrlPublicKeyInvalid, setDynamicUrlPublicKeyInvalid ] = useState(false)
  const [ integration, setIntegration ] = useState()
  const [ invalidPriceConfiguration, setInvalidPriceConfiguration ] = useState()
  const [ invalidTokensConfiguration, setInvalidTokensConfiguration ] = useState()
  const [ invalidAmountConfiguration, setInvalidAmountConfiguration ] = useState()
  const [ invalidReceiverConfiguration, setInvalidReceiverConfiguration ] = useState()
  const [ invalidConfiguration, setInvalidConfiguration ] = useState()
  const priceInputElement = useRef()
  const dynamicUrlInputElement = useRef()
  const dynamicUrlPublicKeyInputElement = useRef()
  const redirectInputElement = useRef()
  const callbackInputElement = useRef()
  const eventsInputElement = useRef()
  const tokensHeadlineElement = useRef()
  const domainsHeadlineElement = useRef()

  const [ storeIntegration ] = useDebounce(integration, 500)
  useEffect(()=>{
    if(storeIntegration != undefined) {
      Store.dispatch({ type: 'integrations/update', integration: storeIntegration })
      POST(`/api/accounts/${account.id}/integrations/${integration.id}`, storeIntegration)
    }
  }, [ storeIntegration ])

  const updateIntegration = (data) => {
    setIntegration(Object.assign({}, integration, data))
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
      GET(`/api/accounts/${account.id}/integrations`).then((integrations)=>{
        Store.dispatch({ type: 'integrations/set', integrations })
        let integration = Object.assign({}, integrations.find((integration)=>integration.id == id))
        if(!integration.configuration) { integration.configuration = {} }
        setCallbackUrl(integration.callback_url || '')
        setEventUrl(integration.event_url || '')
        setDynamicUrl(integration.dynamic_url || '')
        setRedirectUrl(integration.redirect_url || '')
        setDynamicUrlPublicKey(integration.dynamic_public_key || '')
        if(!(integration.domains instanceof Array)){
          integration.domains = []
        }
        setIntegration(integration)
      })
    })
  }, [])

  useEffect(()=>{

    if(!integration){ return }

    let invalidTokensConfiguration = false
    let invalidPriceConfiguration = false
    let invalidAmountConfiguration = false
    let invalidReceiverConfiguration = false

    if(integration.dynamic) { // dynamic
    } else if(integration?.configuration?.acceptedPayments) {
      invalidTokensConfiguration = !integration?.configuration?.acceptedPayments.length
    } else {
      invalidTokensConfiguration = !account?.accepted?.length
    }

    if(!invalidTokensConfiguration) {

      if(integration.dynamic != true) {
        
        if(integration.fixed_price) {
          if(integration.currency) {
            invalidPriceConfiguration = (!integration.price || integration.price <= 0)
          } else { // denominated in token
            if(integration?.configuration?.acceptedPayments) {
              invalidAmountConfiguration = !integration?.configuration?.acceptedPayments || integration?.configuration?.acceptedPayments.length <= 0 || !!integration.configuration.acceptedPayments.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))
            } else {
              invalidAmountConfiguration = integration.amounts && (!account.accepted || account.accepted.length <= 0 || !!integration.amounts.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))) || !integration.amounts
            }
          }
        } else { // user can choose amount or price dynamic
        }

        const acceptedConfiguration = integration?.configuration?.acceptedPayments || account?.accepted
        invalidReceiverConfiguration = !!(acceptedConfiguration || []).find((configuration)=>{
          return !configuration.receiver || !isValidAddress(configuration?.token?.blockchain, configuration.receiver)
        })
      }
    }

    setInvalidTokensConfiguration(invalidTokensConfiguration)
    setInvalidPriceConfiguration(invalidPriceConfiguration)
    setInvalidAmountConfiguration(invalidAmountConfiguration)
    setInvalidReceiverConfiguration(invalidReceiverConfiguration)

    setInvalidConfiguration(
      invalidTokensConfiguration ||
      invalidPriceConfiguration ||
      invalidAmountConfiguration ||
      invalidReceiverConfiguration ||
      (integration.dynamic && dynamicUrlInvalid) ||
      (integration.dynamic && dynamicUrlPublicKeyInvalid) ||
      redirectUrlInvalid ||
      callbackUrlInvalid ||
      eventUrlInvalid
    )

  }, [integration])

  if(integration == null) { return null }

  return(
    <div className="integrations-screen px-3">

      <div className="row">

        <div className="col-lg-5 pt-4 pb-2 pb-lg-5">
          <form onSubmit={(event)=>{ event.preventDefault() }}>
            <div className="form-group pb-2 pt-4 mb-2">
              <div className="pt-3">
                <label htmlFor="integrationName" className="pb-1">
                  <h5>Name</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <input 
                  type="text"
                  aria-describedby="integrationNameHelp"
                  placeholder="My Project Name"
                  id="integrationName"
                  name="integrationName"
                  className="form-control font-size-s"
                  value={ integration.name || '' } 
                  onChange={ (event)=>{
                    updateIntegration({ name: event.target.value })
                  }} 
                />
              </div>
              <div className='line-height-extra-small pt-1'>
                <small id="integrationNameHelp" className="form-text text-muted">
                  Give this integration a name so you can identify it.
                </small>
              </div>
            </div>

            <div className="form-group pb-2 pt-3 mb-2">
              <div>
                <label className="pb-1">
                  <h5>Dynamic configuration</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  If enabled, allows you to provide dynamic configuration data (like pricing) from your backend.
                </small>
              </div>
              <div className="pt-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="switchDynamicPayments"
                    checked={integration.dynamic}
                    onChange={()=>updateIntegration({ dynamic: !integration.dynamic })}
                  />
                  <label className="form-check-label" htmlFor="switchDynamicPayments">
                    { integration.dynamic && <span>Click to disable</span> }
                    { !integration.dynamic && <span>Click to enable</span> }
                  </label>
                </div>
              </div>

              { integration.dynamic &&
                <div className="pt-1">
                  <div className='line-height-extra-small pt-2'>
                    <label className="h4 line-height-extra-small form-text text-muted" htmlFor="dynamicUrl">
                      Endpoint
                    </label>
                  </div>
                  <div className="pt-2 pb-1">
                    <input 
                      type="url"
                      pattern="https://.+"
                      placeholder="https://myserver.com/depay/payment"
                      id="dynamicUrl"
                      name="dynamicUrl"
                      ref={dynamicUrlInputElement}
                      className={`form-control font-size-s ${dynamicUrlInvalid ? 'is-invalid' : ''}`}
                      value={ dynamicUrl } 
                      onChange={ (event)=>{
                        let value = event.target.value
                        setDynamicUrl(value)
                        if(value?.length != 0 && !isValidHttpsUrl(value)){
                          value = null
                          setDynamicUrlInvalid(true)
                        } else {
                          setDynamicUrlInvalid(false)
                        }
                        updateIntegration({ dynamic_url: value })
                      }}
                    />
                    {
                      dynamicUrlInvalid &&
                      <div className="invalid-feedback">
                        Please provide a valid url with https://
                      </div>
                    }
                  </div>
                  <div className='line-height-extra-small pt-2'>
                    <label className="h4 line-height-extra-small form-text text-muted" htmlFor="dynamicUrlPublicKey">
                      Public Key
                    </label>
                  </div>
                  <div className="pt-2">
                    <textarea 
                      placeholder="-----BEGIN PUBLIC KEY-----&#10;MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuOk3XwmFNuUl8m16g8Rr&#10;mDKH0wqkd9OohFTEu0J/Ilk8UoexR6BTVQEGWHgxFisQjwT4r1U2VuTVfMONiuQE&#10;wh+yx2VhtRmoN+AWRy05YEzvqBylG6s0gcq0K+rHe3Ve1clT+FHtHfjQtGcZKsK8&#10;ToU+TXb7m3r+B5aCcA9miEhEk9Bf4nH5jyHPK38eVZhHhwkzynsVzFwFAt8172y+&#10;Y10Y4ZNzIpb8jvVrXrOqc8VTXb3/cza0UlcUg3+dN51rIjleABuHCl8nmwrm9DWy&#10;nZPVG2PTi8u90Pr9+szkxUHKDbG/WlMHNvG1kd68WDRfbEhRkp87rTz0/FQWgKd4&#10;iwIDAQAB&#10;-----END PUBLIC KEY-----"
                      id="dynamicUrlPublicKey"
                      ref={dynamicUrlPublicKeyInputElement}
                      name="dynamicUrlPublicKey"
                      className={`form-control font-size-s ${dynamicUrlPublicKeyInvalid ? 'is-invalid' : ''}`}
                      value={ dynamicUrlPublicKey } 
                      onChange={ (event)=>{
                        let value = event.target.value.trim().replaceAll('\\n', "\n").replace(/\n$/, '')
                        setDynamicUrlPublicKey(value)
                        if(value?.length != 0 && !isValidPublicKey(value)){
                          value = null
                          setDynamicUrlPublicKeyInvalid(true)
                        } else {
                          setDynamicUrlPublicKeyInvalid(false)
                        }
                        updateIntegration({ dynamic_public_key: value })
                      }}
                      style={{ resize: 'none' }}
                      rows={ 4 }
                    />
                    {
                      dynamicUrlPublicKeyInvalid &&
                      <div className="invalid-feedback">
                        Please provide a valid public key!
                      </div>
                    }
                  </div>
                </div>
              }
            </div>

            { !integration.dynamic &&
              <div>
                <div className="form-group pb-2 pt-1">
                  <div className="pt-3">
                    <label className="pb-1 user-select-none">
                      <h5>Price</h5>
                    </label>
                  </div>
                  <div className="pt-1 pb-2">
                    <select className="form-select" value={ integration.fixed_price } onChange={ (event)=>{ 
                      updateIntegration({ fixed_price: event.target.value.toString() === 'true' })
                    }}>
                      <option value={ true }>Fixed price</option>
                      <option value={ false }>Users can choose amount</option>
                    </select>
                  </div>
                  {
                    integration.fixed_price &&
                    <div className="pt-1 pb-1">
                      <div className="form-check pt-1 pb-1">
                        <label className="form-check-label user-select-none">
                          <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !!integration.currency } onChange={ ()=>{ 
                            let newIntegration = JSON.parse(JSON.stringify(integration))
                            newIntegration.configuration.acceptedPayments = (newIntegration.configuration.acceptedPayments || []).map((payment)=>{ return { token: payment.token, receiver: payment.receiver }})
                            updateIntegration({...newIntegration, currency: integration.currency || 'USD', price: '' })
                          }}/>
                          Denominated in a currency
                        </label>
                      </div>
                      <div className="form-check pb-1">
                        <label className="form-check-label user-select-none">
                          <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !integration.currency } onChange={ ()=>{ 
                            updateIntegration({ currency: undefined, price: undefined })
                          }}/>
                          Denominated per token
                        </label>
                      </div>
                      {
                        integration.currency &&
                        <div className="pt-1 pb-1 row">
                          <div className="col-4">
                            <select className="form-select" value={ integration.currency } onChange={ (event)=>{ 
                              updateIntegration({ currency: event.target.value }) 
                            }}>
                              { all.map((currency)=>{
                                  return(
                                    <option key={ currency } value={ currency }>{ currency }</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                          <div className="col-8 ps-2">
                            <input
                              required
                              className="form-control w-100"
                              placeholder="Enter price"
                              ref={priceInputElement}
                              onChange={ (event)=>{ 
                                updateIntegration({ price: event.target.value }) 
                              }}
                              onBlur={ (event)=>{ 
                                let value = event.target.value
                                if(value && value.length) {
                                  updateIntegration({ price: parseFloat(value).toFixed(2) }) 
                                }
                              }}
                              type="number"
                              step="1"
                              value={ integration.price || '' }
                            />
                          </div>
                        </div>
                      }
                    </div>
                  }
                  
                </div>

                <div className="form-group pb-2">
                  <div className="pt-3">
                    <label className="pb-1 user-select-none position-relative">
                      <a name="tokens" className="anchor"/>
                      <h5 ref={tokensHeadlineElement}>Tokens</h5>
                    </label>
                  </div>
                  <div className='line-height-extra-small pt-1'>
                    <small className="form-text text-muted">
                      { integration.type == 'payment' && "Please select the tokens that you would like to receive as means of payment:" }
                      { integration.type == 'donation' && "Please select the tokens that you would like to receive as donation:" }
                      { integration.type == 'tip' && "Please select the tokens that you would like to receive:" }
                    </small>
                  </div>

                  <div className="pt-3 pb-2">
                    <AcceptedPayments
                      acceptedPayments={ integration?.configuration?.acceptedPayments }
                      amounts={ integration.amounts }
                      withAmounts={ !integration.currency && integration.fixed_price }
                      onChange={ (accepted)=>{
                        if(integration.configuration?.acceptedPayments || accepted?.length === 0 || !account?.accepted || account.accepted.length == 0) {
                          let newIntegration = JSON.parse(JSON.stringify(integration))
                          if(!newIntegration.configuration) {
                            newIntegration.configuration = {}
                          }
                          newIntegration.configuration.acceptedPayments = accepted
                          newIntegration.amounts = undefined
                          setIntegration(newIntegration)
                        } else {
                          let newIntegration = JSON.parse(JSON.stringify(integration))
                          if(!newIntegration.configuration) {
                            newIntegration.configuration = {}
                          }
                          newIntegration.configuration.acceptedPayments = undefined
                          newIntegration.amounts = accepted.map((configuration)=>{
                            return({
                              amount: configuration.amount,
                              token: configuration.token
                            })
                          })
                          setIntegration(newIntegration)
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group pb-2">
                  <div className="pt-2">
                    <label className="pb-1 user-select-none" htmlFor="redirectUrl">
                      <h5>Redirect</h5>
                    </label>
                  </div>
                  <div className='line-height-extra-small pt-1'>
                    <small className="form-text text-muted" id="redirectHelp">
                      After a successful payment, redirect the user to the following url (optional):
                    </small>
                  </div>

                  <div className="pt-2">
                    <input 
                      type="url"
                      pattern="https://.+"
                      aria-describedby="redirectHelp"
                      placeholder="https://myserver.com/success"
                      id="redirectUrl"
                      ref={redirectInputElement}
                      name="redirectUrl"
                      className={`form-control font-size-s ${redirectUrlInvalid ? 'is-invalid' : ''}`}
                      value={ redirectUrl } 
                      onChange={ (event)=>{
                        let value = event.target.value
                        setRedirectUrl(value.trim())
                        if(value?.length != 0 && !isValidHttpsUrl(value)){
                          value = null
                          setRedirectUrlInvalid(true)
                        } else {
                          setRedirectUrlInvalid(false)
                        }
                        updateIntegration({ redirect_url: value })
                      }}
                    />
                    {
                      redirectUrlInvalid &&
                      <div className="invalid-feedback">
                        Please provide a valid url with https://
                      </div>
                    }
                  </div>
                </div>

              </div>
            }

            <div className="form-group pb-2 pt-3 mb-2">
              <div>
                <label className="pb-1" htmlFor="callbackUrl">
                  <h5>Callback</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted" id="callbackHelp">
                  Configure an endpoint to be called for every successful payment and controll the payment flow (optional):
                </small>
              </div>
              <div className="pt-2">
                <input 
                  type="url"
                  pattern="https://.+"
                  aria-describedby="callbackHelp"
                  placeholder="https://myserver.com/depay/callback"
                  id="callbackUrl"
                  ref={callbackInputElement}
                  name="callbackUrl"
                  className={`form-control font-size-s ${callbackUrlInvalid ? 'is-invalid' : ''}`}
                  value={ callbackUrl } 
                  onChange={ (event)=>{
                    let value = event.target.value
                    setCallbackUrl(value)
                    if(value?.length != 0 && !isValidHttpsUrl(value)){
                      value = null
                      setCallbackUrlInvalid(true)
                    } else {
                      setCallbackUrlInvalid(false)
                    }
                    updateIntegration({ callback_url: value })
                  }}
                />
                {
                  callbackUrlInvalid &&
                  <div className="invalid-feedback">
                    Please provide a valid url with https://
                  </div>
                }
              </div>
            </div>

            <div className="form-group pb-2 pt-3 mb-2">
              <div>
                <label className="pb-1" htmlFor="eventUrl">
                  <h5>Events</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted" id="eventHelp">
                  Configure an endpoint to be called for every event during the payment flow (optional):
                </small>
              </div>
              <div className="pt-2">
                <input 
                  type="url"
                  pattern="https://.+"
                  aria-describedby="eventHelp"
                  placeholder="https://myserver.com/depay/event"
                  id="eventUrl"
                  ref={eventsInputElement}
                  name="eventUrl"
                  className={`form-control font-size-s ${eventUrlInvalid ? 'is-invalid' : ''}`}
                  value={ eventUrl } 
                  onChange={ (event)=>{
                    let value = event.target.value
                    setEventUrl(value)
                    if(value?.length != 0 && !isValidHttpsUrl(value)){
                      value = null
                      setEventUrlInvalid(true)
                    } else {
                      setEventUrlInvalid(false)
                    }
                    updateIntegration({ event_url: value })
                  }}
                />
                {
                  eventUrlInvalid &&
                  <div className="invalid-feedback">
                    Please provide a valid url with https://
                  </div>
                }
              </div>
            </div>

            <div className="form-group pb-2 pt-3 mb-2">
              <div>
                <h5>Verify</h5>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  Use the following public key to verify any communication from DePay's APIs to your systems.
                </small>
              </div>
              <div className="pt-2">
                <PublicKey publicKey={ integration.public_key }/>
              </div>
            </div>

            <div className="form-group pb-2 pt-3 mb-2">
              <div>
                <label className="pb-1">
                  <h5 ref={domainsHeadlineElement}>Domains</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1 pb-1'>
                <small className="form-text text-muted" id="callbackHelp">
                  Restrict the integration to only be enabled for the given domains (optional):
                </small>
              </div>
              <div>
                {
                  integration.domains.map((domain, index)=>{
                    return(
                      <div key={ index } className="pt-1">
                        <div className="row">
                          <div className="col-10">
                            <input 
                              type="text"
                              aria-describedby="dynamicUrlHelp"
                              placeholder="mydomain.com"
                              className={`form-control font-size-s`}
                              value={ domain } 
                              onChange={ (event)=>{
                                let value = event.target.value
                                let newIntegration = JSON.parse(JSON.stringify(integration))
                                newIntegration.domains[index] = value
                                setIntegration(newIntegration)
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <button title="Remove domain" type="button" className="btn btn-sm btn-grey font-size-xs mt-1" onClick={ ()=> {
                              let value = event.target.value
                              let newIntegration = JSON.parse(JSON.stringify(integration))
                              newIntegration.domains.splice(index, 1)
                              setIntegration(newIntegration)
                            } }>
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div className='pt-2'>
                  <button type="button" className="btn btn-action btn-sm me-3"
                    onClick={ ()=>{
                      let newIntegration = JSON.parse(JSON.stringify(integration))
                      newIntegration.domains = [...newIntegration.domains, '']
                      setIntegration(newIntegration)
                    }}
                  >
                    <i className="fas fa-plus me-2"></i>
                    Add Domain
                  </button>
                </div>
              </div>
            </div>

            <input type='submit' className="d-none"/>
          </form>
        </div>

        <div className="col-lg-7 sticky-wrapper">
          <div className="sticky-item">
            <div className="pt-3 pe-2 mb-5">
              <div className="ps-0 ps-lg-5 pt-0 pt-lg-5">
                <div className="pt-2">
                  
                  { invalidConfiguration === true &&
                    <div className="border-danger alert alert-info mb-2 rounded-1 px-2 py-1 d-inline-block w-100">
                      <div className="px-1 pt-1">
                        <h5 className="text-danger ms-1 flex-grow-1">Invalid configuration</h5>
                      </div>
                      <div>
                        <div className="mb-2 pt-1 w-100">
                          { invalidTokensConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please add tokens you accept as payment</button> }
                          { invalidPriceConfiguration && <button onClick={()=>priceInputElement.current.focus()} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid price</button> }
                          { invalidAmountConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set valid amounts for all tokens</button> }
                          { invalidReceiverConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid receiver for each token</button> }
                          { dynamicUrlInvalid && <button onClick={()=>{ dynamicUrlInputElement.current.focus() }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid configuration url</button> }
                          { dynamicUrlPublicKeyInvalid && <button onClick={()=>{ dynamicUrlPublicKeyInputElement.current.focus() }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid public key</button> }
                          { redirectUrlInvalid && <button onClick={()=>{ redirectInputElement.current.focus() }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid redirect url</button> }
                          { callbackUrlInvalid && <button onClick={()=>{ callbackInputElement.current.focus() }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid callback url</button> }
                          { eventUrlInvalid && <button onClick={()=>{ eventsInputElement.current.focus() }} type="button" className="btn-action px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid event url</button> }
                        </div>
                      </div>
                    </div>
                  }

                  { invalidConfiguration === false &&

                    <div>
                      <ul className="nav nav-pills mb-2" role="tablist">
                        <li className="nav-item pe-1" role="presentation">
                          <button disabled={!integrationLogs?.length} onClick={()=>{ setShowTab('logs') }} className={`nav-link btn btn-sm ${showTab == 'logs' ? 'active' : ''}`} id="pills-logs-tab" type="button" role="tab" aria-controls="pills-code" aria-selected={ showTab == 'logs' ? 'true' : 'false' }>
                            Logs
                          </button>
                        </li>
                        <li className="nav-item pe-1" role="presentation">
                          <button onClick={()=>{ setShowTab('code') }} className={`nav-link btn btn-sm ${showTab == 'code' ? 'active' : ''}`} id="pills-code-tab" type="button" role="tab" aria-controls="pills-code" aria-selected={ showTab == 'code' ? 'true' : 'false' }>
                            Code
                          </button>
                        </li>
                        { integration.form == 'widget' && 
                          <li className="nav-item" role="presentation">
                            <button onClick={()=>{ setShowTab('installation') }} className={`nav-link btn btn-sm ${showTab == 'installation' ? 'active' : ''}`} id="pills-installation-tab" type="button" role="tab" aria-controls="pills-installation" aria-selected={ showTab == 'installation' ? 'true' : 'false' }>
                              Installation
                            </button>
                          </li>
                        }
                        <li className="nav-item" role="presentation">
                          <a className="nav-link btn btn-sm fw-normal" href={DOCUMENTATION_LINKS[integration.type][integration.form]} target="_blank" rel="noopener noreferrer">
                            Docs
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div className={`tab-pane fade ${showTab == 'logs' ? 'show active' : ''}`} id="pills-logs" role="tabpanel" aria-labelledby="pills-logs-tab">
                          <IntegrationLogsCard integration={ integration } firstLogCallback={ ()=>setShowTab('logs') } />
                        </div>
                        <div className={`tab-pane fade ${showTab == 'code' ? 'show active' : ''}`} id="pills-code" role="tabpanel" aria-labelledby="pills-code-tab">
                          <CodeCard integration={ integration } />
                        </div>
                        <div className={`tab-pane fade ${showTab == 'installation' ? 'show active' : ''}`} id="pills-installation" role="tabpanel" aria-labelledby="pills-installation-tab">
                          <InstallationCard />
                        </div>
                      </div>
                    </div>

                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
