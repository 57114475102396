import getInitials from "../helpers/getInitials"
import LightOrDark from '../helpers/LightOrDark'
import React, { useState, useEffect } from "react"
import { GET } from "../helpers/Queries"
import { useSelector } from 'react-redux'

export default function(props) {
    
  return(

    <div key={ props.account.id } className="circular-icon me-1" style={{ left: '2px', backgroundColor: props.account.logo ? 'rgba(0,0,0,0.3)' : props.account.color, backgroundImage: props.account.logo ? `url(https://uploads.depay.com/${props.account.logo})` : 'none', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      { !props.account.logo &&
        <span className={ LightOrDark(props.account.color || '#000000') == 'light' ? 'text-black' : 'text-white' }>{
          props.account.name ? getInitials(props.account.name) : null
        }</span>
      }
    </div>

  )
}
