import Dropdown from '../components/Dropdown'
import ExportXLSX from "../helpers/ExportXLSX"
import loadAdditionalTokenDataForPayments from "../helpers/loadAdditionalTokenDataForPayments"
import LoadingSpinner from "../components/LoadingSpinner"
import moment from 'moment'
import Onboarding from '../components/Onboarding'
import Payment from "../models/Payment"
import PaymentDetails from '../components/payments/Details'
import PaymentList from '../components/payments/List'
import React, { useEffect, useState } from "react"
import Token from "../models/Token"
import { GET } from "../helpers/Queries"
import { NavLink, useParams } from "react-router-dom"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const payments = useSelector(state => state.payments)
  const tokens = useSelector(state => state.tokens)
  const integrations = useSelector(state => state.integrations)
  const [ disabled, setDisabled ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ noPayments, setNoPayments ] = useState(false)
  const [ initialLoading, setInitialLoading ] = useState(true)
  const [ hasMore, setHasMore ] = useState(true)
  const [ preparingExport, setPreparingExport ] = useState(false)
  const [ exportReady, setExportReady ] = useState()
  const [ exportData, setExportData ] = useState()
  const { id: selectedPaymentId } = useParams()
  const activeSubscription = useSelector(state => state.subscription)
  let [ selectedPayment, setSelectedPayment ] = useState()
  let [ accountWalletIds, setAccountWalletIds ] = useState({})
  let [ selectedPaymentIntegration, setSelectedPaymentIntegration ] = useState()

  const exportNow = (data)=>{
    let schema = {
      confirmed_at: (value)=>(new Date(value)).toISOString().slice(0,19).replace('T', ' '),
      blockchain: (value)=>value,
      transaction_id: (value)=>value,
      sender_id: (value)=>value,
      receiver_id: (value)=>value,
      receiver_token: (value, row)=>tokens.byId[`${row.blockchain}-${row.receiver_token_id}`]?.symbol,
      receiver_token_id: (value)=>value,
      receiver_amount: (value)=>value,
      receiver_amount_usd: (value)=>value,
    }
    if(data.some((datum)=>datum.form_data)) {
      let allFormFields = {}
      data.filter((datum)=>datum.form_data).forEach((datum)=>{
        Object.keys(datum.form_data).forEach((key)=>{
          schema[key] = ['form_data', (value)=>value]
        })
      })
    }
    ExportXLSX(data || exportData, schema, !activeSubscription)
  }

  const exportFile = ()=>{
    let paymentsToExport = []
    setExportReady()
    setPreparingExport(true)
    const loadMoreForExportFile = (oldestPayment)=>{
      GET(`/api/accounts/${account.id}/payments?after=${oldestPayment?.confirmed_at || ''}`).then(async (paymentsAfter)=>{
        await loadAdditionalTokenDataForPayments(paymentsAfter)
        if(!activeSubscription){ paymentsToExport = paymentsAfter }
        if(paymentsAfter.length == 0 || !activeSubscription){
          setExportReady(true)
          setExportData(paymentsToExport)
          exportNow(paymentsToExport)
        } else {
          paymentsToExport = paymentsToExport.concat(paymentsAfter)
          loadMoreForExportFile(paymentsAfter[paymentsAfter.length-1])
        }
      })
    }
    loadMoreForExportFile()
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/integrations`).then((integrations)=>{
      Store.dispatch({ type: 'integrations/set', integrations })
    })
  }, [])

  useEffect(()=>{
    let integration
    if(selectedPayment && selectedPayment.data && selectedPayment.data.integration_id) {
      integration = integrations.find((integration)=>{ return integration.id == selectedPayment.data.integration_id })
    }
    setSelectedPaymentIntegration(integration)
  }, [selectedPayment])

  useEffect(()=>{
    if(accountWallets && accountWallets.length && selectedPaymentId && payments.byId[selectedPaymentId]) {
      setSelectedPayment(new Payment(payments.byId[selectedPaymentId], accountWallets))
    } else {
      setSelectedPayment()
    }
  }, [accountWallets, selectedPaymentId, account, payments])

  useEffect(()=>{
    if(account) {
      GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
        Store.dispatch({ type: 'accountWallets/set', accountWallets })
        GET(`/api/accounts/${account.id}/payments`).then((latestPayments)=>{
          setInitialLoading(false)
          if(latestPayments.length == 0) {
            setHasMore(false)
            setNoPayments(true)
          } else {
            const latestGroup = payments.byGroupId[payments.groups[0]]
            let latestPayment
            if(latestGroup) { latestPayment  = payments.byId[latestGroup[0]] }
            if(latestPayment && latestPayment.confirmed_at == latestPayments[0].confirmed_at){
              // up to date
            } else {
              loadAdditionalTokenDataForPayments(latestPayments)
              Store.dispatch(({ type: 'payments/set', payments: latestPayments }))
            }
          }
        })
      })
    }
  }, [account])

  const loadMore = ()=>{
    if(payments.groups.length == 0) { return }
    const oldestGroup = payments.byGroupId[payments.groups[payments.groups.length-1]]
    const oldestPayment = payments.byId[oldestGroup[oldestGroup.length-1]]
    GET(`/api/accounts/${account.id}/payments?after=${oldestPayment.confirmed_at}`).then((paymentsAfter)=>{
      if(paymentsAfter.length == 0){
        setHasMore(false)
      } else {
        loadAdditionalTokenDataForPayments(paymentsAfter)
        Store.dispatch(({ type: 'payments/add', payments: paymentsAfter }))
      }
    })
  }

  if(initialLoading) { return(
    <div className="payments-screen px-3 pt-5 pb-4">
      <div className="col-lg-6 pt-3">
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
      </div>
    </div>
  ) }

  if(noPayments) {
    return(
      <div className="payments-screen px-3 position-relative">

        <div className="px-2 px-lg-0 pt-5">

          <div className="row pt-4 pt-lg-3">
            <div className="col">
              <h2 className="pb-2">No payments, yet 💸</h2>
              <div className="font-size-xl">Let's get started with Web3 Payments</div>
            </div>
          </div>

          <Onboarding/>
          
        </div>
      </div>
    )
  }

  return(

    <div className="payments-screen px-3 position-relative">

      <div className="row">

        <div className="col-lg-6 pb-4">
          <div className="row pt-5">
            <div className="col-6"></div>
            <div className="col-6 text-end">
              <Dropdown
                toggle={
                  <button className="btn btn-action-hover" type="button">
                    <i className="fas fa-ellipsis-v text-black-50"></i>
                  </button>
                }
                children={[
                  ['Export File', exportFile, <i className="fa-solid fa-file-export"></i>]
                ]}
              />
            </div>
          </div>

          { preparingExport &&
            <div className="pt-2 pb-2">
              <div className="card d-block px-3 pb-3">
                <div className="row d-flex align-items-center">
                  <div className="col-2 col-lg-1 text-center">
                    { !exportReady && <LoadingSpinner className='small'/> }
                    { exportReady && <i className="fa-solid fa-file font-size-xl"></i> }
                  </div>
                  <div className="col-6 line-height-extra-small ps-lg-2">
                    <div>
                      <span className="font-weight-medium">
                        { !exportReady && "Preparing export..." }
                        { exportReady && "Export ready" }
                      </span>
                    </div>
                    <div className="opacity-50">
                      <small>
                        { !exportReady && "Please wait" }
                        { exportReady && "Download now" }
                      </small>
                    </div>
                  </div>
                  <div className="col-4 col-lg-5 line-height-extra-small text-end pe-1">
                    { exportReady &&
                      <button className="btn btn-action" onClick={()=>exportNow()}>
                        Download
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <PaymentList
            payments={ payments }
            link={ (id)=>(`/payments/${id}`) }
            loadMore={ loadMore }
            hasMore={ hasMore }
          />
          
        </div>

        { selectedPayment &&
          <div className="col-lg-6 sticky-wrapper">
            <div className="sticky-item mobile-sticky-overlay pb-4 pb-lg-0">
              <div className="pt-5 pt-lg-5 px-3 px-lg-0">
                <div className="ps-lg-4 pb-lg-3 pe-lg-3 pt-3 pt-lg-4">
                  <PaymentDetails payment={ selectedPayment } linkBack='/payments'/>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
